.statusContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.statusIndicator {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block; }

.online {
  background-color: #58A60E; }

.offline {
  background-color: #B4B1B1; }

.statusText {
  padding-left: 3px;
  margin-top: 1px; }
