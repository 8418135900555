/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.buttonContainer .button {
  background: none;
  border: none;
  color: #4b45da;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 17px !important;
  padding: 2px;
  position: relative;
  z-index: 100; }
  .buttonContainer .button:hover {
    color: #290088; }
  .buttonContainer .button:disabled {
    opacity: 0.5; }

.formGroupHorizontal input {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .formGroupHorizontal input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .formGroupHorizontal input:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .formGroupHorizontal input::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .formGroupHorizontal input:-ms-input-placeholder {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .formGroupHorizontal input:focus {
    outline: none;
    border-color: #4b45da; }
  .formGroupHorizontal input:disabled {
    background-color: #dbdcdd; }

.formGroupHorizontal input {
  font-size: 14px;
  height: 30px;
  width: 200px; }

.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0 32px;
  width: 534px;
  min-height: 206px; }

.formGroupHorizontal {
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  -ms-flex-align: center;
      align-items: center; }
  .formGroupHorizontal label {
    -ms-flex-pack: left;
        justify-content: left;
    margin-right: 8px; }
  .formGroupHorizontal textarea {
    color: #4b4f54;
    height: 92px;
    border-radius: 4px; }
  .formGroupHorizontal input {
    font-size: 16px; }

.formGroupHorizontal:last-of-type {
  margin-bottom: 32px; }

.horizontalDivider {
  margin: 14px 0;
  width: 100%;
  height: 1px;
  background-color: #d9d9d9; }

.horizontalDashedDivider {
  margin: 14px 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #d9d9d9; }

.rowContainer, .topRowContainer, .publicLinkRowContainer, .buttonContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px; }

.topRowContainer {
  margin-top: 10px; }

.publicLinkRowContainer {
  width: 100%; }

.buttonContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto; }
  .buttonContainer > * {
    margin: 0 0 0 14px; }

.copiedContainer {
  position: absolute;
  left: 10px;
  right: 0;
  margin: auto;
  top: -21px;
  font-size: 10px;
  color: #00afaa; }

.columnContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  font-size: 14px;
  -ms-flex-align: start;
      align-items: flex-start; }

.optionalContainer {
  color: #4b4f54;
  opacity: 0.5;
  font-style: italic;
  margin-left: 8px; }

.inviteLinkContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 8px;
  width: 100%; }
  .inviteLinkContainer .inviteLink {
    text-align: left;
    font-size: 14px;
    padding: 6px 8px;
    width: 100%;
    height: 30px;
    background-color: rgba(216, 216, 216, 0.5);
    border-radius: 4px;
    color: #00afaa;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .inviteLinkContainer .inviteIcon {
    color: #4b45da;
    margin-left: 8px;
    width: 24px;
    height: 24px;
    font-size: 20px;
    cursor: pointer; }

.publicLinkContainer {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  .publicLinkContainer .publicLinkLabel {
    white-space: nowrap;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .publicLinkContainer .publicLink {
    color: #00afaa;
    margin-left: 6px;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

.shareWithInput {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 30px; }
