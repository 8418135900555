/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  width: 25%; }
  .container .tile {
    cursor: pointer;
    display: block;
    margin: 17px 6px;
    padding: 0;
    min-width: 165px;
    width: 100%; }
    .container .tile .videoPreview {
      background-color: #e9eaee;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px solid #d8d8d8;
      border-radius: 2px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      height: 0;
      padding-top: calc(100% * 0.5625);
      position: relative;
      width: 100%; }
      .container .tile .videoPreview .duration {
        background-color: #000000;
        border-radius: 2px;
        bottom: 5px;
        color: #ffffff;
        font-size: 10px;
        font-weight: bold;
        padding: 1px 5px 0;
        position: absolute;
        right: 5px; }
    .container .tile ul {
      list-style: none;
      margin: 6px 0 0;
      padding: 0; }
      .container .tile ul li {
        font-weight: 200;
        line-height: 1.6;
        overflow: hidden;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        white-space: nowrap; }
        .container .tile ul li.title {
          color: #00afaa; }
        .container .tile ul li.owner {
          color: #231f20;
          font-size: 10px; }
        .container .tile ul li.meeting {
          color: #00afaa;
          font-size: 10px;
          min-height: 1.6em; }
        .container .tile ul li.stats {
          -ms-flex-align: center;
              align-items: center;
          color: #231f20;
          display: -ms-flexbox;
          display: flex;
          font-size: 9px;
          line-height: 1;
          margin-top: 8px; }
          .container .tile ul li.stats .divider {
            color: #d8d8d8;
            margin: 0 7px; }
          .container .tile ul li.stats .icon {
            color: #767676;
            font-size: 14px;
            margin: 0; }

@media (max-width: 1353px) {
  .container {
    width: calc(100% / 3); } }
