.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
  bottom: 3px;
  width: auto;
  height: 29px;
  border-radius: 3px;
  border: 1px solid #E9EAEE;
  background-color: #E9EAEE;
  padding: 5px 10px; }
  .container div {
    width: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    font-size: 11px; }
  .container i {
    display: -ms-flexbox;
    display: flex;
    color: #4b45da;
    font-size: 13px; }
  .container .message {
    display: -ms-flexbox;
    display: flex;
    width: auto; }

.loading {
  left: calc(100% / 2 - 106px);
  max-width: 132px; }

.done {
  left: calc(100% / 2 - 125px);
  max-width: 197px; }
