.container {
  height: 30px;
  width: 30px;
  cursor: pointer; }
  .container .menuIcon {
    -ms-flex-align: end;
        align-items: flex-end;
    background-color: #d8d8d8;
    border-radius: 50%;
    color: #7D7B7B;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 27px;
    height: 30px;
    -ms-flex-pack: center;
        justify-content: center;
    overflow: hidden;
    position: relative;
    width: 30px; }
    .container .menuIcon .icon {
      margin-bottom: -2px; }
  .container .menuOverride {
    border: none !important;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
    margin: 6px 0 0 !important;
    min-width: 136px !important;
    z-index: 100 !important; }
    .container .menuOverride div:global(.divider) {
      width: 90% !important;
      margin: .1em auto !important; }
    .container .menuOverride div:global(.userProfile)[role='option'] {
      -ms-flex-align: center;
          align-items: center;
      color: #65686C !important;
      display: -ms-flexbox;
      display: flex;
      font-size: 14px !important;
      padding: 10px 8px !important; }
      .container .menuOverride div:global(.userProfile)[role='option']:hover {
        background: transparent !important;
        color: #4b45da !important; }
