/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }
  .container .tab {
    height: 100%; }
  .container .noEditBanner {
    width: 100%;
    color: white;
    background-color: #00c18b;
    min-height: 20px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px; }
  .container .tabsContainer {
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    -ms-flex-direction: column;
        flex-direction: column; }
  .container .mergeContainer {
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    -ms-flex-direction: column;
        flex-direction: column;
    background-color: #f2f2f2;
    padding-top: 8px; }
  .container .mergeTitle {
    display: -ms-flexbox;
    display: flex; }
    .container .mergeTitle > span:first-child {
      margin-right: 5px; }
