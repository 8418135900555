/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.backdrop {
  width: 100%;
  height: 260px;
  -ms-flex-align: center;
  position: absolute;
  z-index: 100;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: -10%;
  margin-top: 142px;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  pointer-events: none; }

.container {
  width: 290px;
  height: 244px;
  -webkit-filter: drop-shadow(3px 3px 3px #ddd);
          filter: drop-shadow(3px 3px 3px #ddd);
  border-radius: 13px;
  background: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 25px;
  pointer-events: auto; }
  .container .logo {
    width: 41px;
    margin-bottom: 20px; }
  .container .title {
    font-size: 14px;
    line-height: normal;
    text-align: center;
    font-family: NimbusSans, sans-serif;
    font-weight: bold;
    color: #000000;
    margin-bottom: 5px; }
  .container .body {
    font-family: NimbusSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #000000;
    text-align: center; }
  .container .blurButton {
    width: 130px;
    height: 29px;
    padding: 9px 18px 9px 19px;
    font-family: NimbusSans, sans-serif;
    border-radius: 16px;
    background-color: #00bbb9;
    cursor: pointer;
    outline: none;
    border: none;
    color: white;
    font-size: 12px !important;
    margin-top: 32px; }
    .container .blurButton:hover, .container .blurButton:active, .container .blurButton:focus {
      background-color: #00bbb9;
      color: white;
      outline: none; }
