/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-item-align: start;
      align-self: flex-start;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-bottom: 0;
  padding-left: 0 !important;
  padding-right: 0 !important; }
  .container.disabled {
    cursor: default; }
  .container .disabled {
    cursor: default; }
  .container .toggle {
    display: inline-block;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    height: 24px;
    position: relative;
    width: 45px !important;
    /* Hide default HTML checkbox */ }
    .container .toggle .slider {
      background-color: white;
      border-radius: 12px;
      border: solid 1px #a5a7aa;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: .2s;
      -o-transition: .2s;
      transition: .2s; }
      .container .toggle .slider:before {
        background-color: #a5a7aa;
        border-radius: 50%;
        bottom: 2px;
        content: "";
        height: 18px;
        left: 3px;
        position: absolute;
        -webkit-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
        width: 18px; }
    .container .toggle input {
      height: 0;
      opacity: 0;
      width: 0; }
      .container .toggle input:checked + .slider {
        border: solid 1px #00c18b; }
      .container .toggle input:checked + .slider:before {
        background-color: #00c18b;
        -webkit-transform: translateX(20px);
            -ms-transform: translateX(20px);
                transform: translateX(20px); }
      .container .toggle input:disabled + .slider {
        opacity: 0.5; }
  .container .label {
    color: #4b4f54;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    font-size: 13px;
    line-height: 1.7;
    padding-left: 16px;
    padding-right: 0;
    padding-top: 0.15em;
    text-align: left; }
    .container .label > * {
      vertical-align: middle; }
  .container.labelOnLeft {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    .container.labelOnLeft .label {
      padding-left: 0;
      padding-right: 16px; }
  .container.labelOnTop {
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    -ms-flex-align: end;
        align-items: flex-end; }
    .container.labelOnTop .label {
      padding-left: 0;
      padding-right: 0;
      margin-top: -5px;
      font-size: 12px !important; }

/**
 Needed to short-circuit transition.
 Otherwise, toggle remains visible for the
 length of the transition when visibility is changed
 */
.hideNow {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }
