/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.tabletTabContainer .disconnectContainer .btnPrimary {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.tabletTabContainer .disconnectContainer .btnPrimary {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .tabletTabContainer .disconnectContainer .btnPrimary:hover {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .tabletTabContainer .disconnectContainer .btnPrimary:disabled {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.tabletTabContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  min-height: 340px;
  -ms-flex-align: center;
      align-items: center; }
  .tabletTabContainer .disconnectContainer {
    -ms-flex: 1 1;
        flex: 1 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: end;
        justify-content: flex-end;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 33px; }
    .tabletTabContainer .disconnectContainer button {
      font-size: 17px !important;
      height: 40px;
      min-width: 150px; }

.title {
  font-size: 20px;
  max-width: 290px; }

.listContainer {
  padding: 0;
  font-size: 16px;
  margin-top: 40px;
  text-align: left; }

.centerContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 32px; }

.linkingCodeContainer {
  margin-left: 16px;
  font-size: 24px;
  font-weight: 500; }

.qrCode {
  margin-right: 16px; }

.appStoreContainer {
  margin: 0 8px; }
