/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.buttonContainer button[role*="button"] {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.buttonContainer button[role*="button"] {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .buttonContainer button:hover[role*="button"] {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .buttonContainer button:disabled[role*="button"] {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.container {
  background-color: #f2f2f2;
  color: #4b4f54;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0;
  padding: 0;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }

.forbidden {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
  margin: 0 8px 8px;
  min-width: 726px;
  padding: 0 6px; }
  .forbidden.loading {
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column;
    height: 150px;
    -ms-flex-pack: center;
        justify-content: center; }
    .forbidden.loading > i {
      font-size: 27px; }

.buttonContainer {
  position: relative; }
  .buttonContainer button[role*="button"] {
    font-size: 14px !important;
    height: 30px;
    min-width: 120px; }

.uploadStatus {
  position: absolute;
  bottom: -20px;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: 150px;
  font-style: italic;
  text-align: right; }

.uploadSuccess {
  color: #00c18b; }
