/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container .error button[role*="button"]:not(.clearSearch):not(.iconButton) {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.container .error button[role*="button"]:not(.clearSearch):not(.iconButton) {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .container .error button:hover[role*="button"]:not(.clearSearch):not(.iconButton) {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .container .error button:disabled[role*="button"]:not(.clearSearch):not(.iconButton) {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.container .breadcrumbs {
  font-weight: 200;
  margin: 12px 0 8px 18px; }

.container .error {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 100px 0;
      flex: 100px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #fff;
  margin: 20px;
  padding: 30px; }
  .container .error .errorTitle {
    color: #c10000;
    font-size: 16px;
    margin-bottom: 20px; }
  .container .error button[role*="button"]:not(.clearSearch):not(.iconButton) {
    height: 30px;
    width: 200px; }
