/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container footer button.button {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.container footer button.button {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .container footer button.button:hover {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .container footer button.button:disabled {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.container {
  padding: 20px 32px 2px;
  width: 478px; }
  .container dl {
    color: #000000;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    margin-bottom: 0.5rem;
    text-align: left; }
    .container dl > * {
      -webkit-margin-start: 0;
              margin-inline-start: 0; }
    .container dl dt {
      font-weight: bold;
      margin-bottom: 16px;
      padding: 0 10px 0 53px;
      width: 50%; }
    .container dl dd {
      font-weight: 200;
      margin-bottom: 16px;
      margin-left: 0;
      width: 50%; }
  .container p {
    color: #4b4f54;
    font-weight: 200;
    font-size: 20px;
    margin: 10px 0 30px; }
  .container a {
    color: #4b45da;
    font-size: 13px;
    padding: 5px; }
  .container footer {
    margin-top: 28px; }
    .container footer button.button {
      font-size: 17px !important;
      height: 40px;
      min-width: 150px;
      padding: 0 15px; }
