/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  min-height: 340px;
  -ms-flex-align: center;
      align-items: center; }

.uploaderContainer {
  width: 100%; }
  .uploaderContainer > span {
    font-family: NimbusSans-Medium, sans-serif;
    font-size: 16px; }

.dropzone {
  border-width: 2px;
  border-color: #4b45da;
  border-style: dashed;
  border-radius: 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  background-color: rgba(245, 245, 245, 0.9);
  height: 150px;
  width: 100%; }
  .dropzone span {
    font-family: NimbusSans-Medium, sans-serif;
    font-size: 16px; }
  .dropzone .icon {
    font-size: 40px;
    color: #757575; }
  .dropzone .browse {
    margin-top: 12px; }
    .dropzone .browse span {
      color: #00afaa;
      font-size: 13px; }
  .dropzone img {
    height: 216px; }
  .dropzone video {
    height: 216px; }

.helper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  width: 100%;
  font-size: 12px;
  margin-top: 5px; }

.errorContainer {
  margin-top: 2px;
  height: 16px; }

.error {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  width: 100%;
  font-size: 13px;
  color: #c10000;
  font-style: italic;
  line-height: initial;
  max-width: 100%;
  padding: 0; }

.statusContainer {
  height: 16px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 13px; }

.uploadStatus {
  font-size: 14px;
  margin-top: 5px;
  font-style: italic; }

.uploadSuccessful {
  margin-top: 65px; }

.gotoVideo {
  margin-top: 20px; }
