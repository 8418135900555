.container {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex: 4 1 100%;
      flex: 4 1 100%;
  margin: 0 8px 8px 0;
  min-width: 500px;
  overflow: hidden;
  padding: 22px 25px;
  width: 100%; }
  .container .label {
    -ms-flex: 4 1 100%;
        flex: 4 1 100%;
    font-size: 16px;
    margin-bottom: 20px; }
