/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }
  .container .content {
    position: relative;
    background-color: #fff;
    padding-top: 43px;
    border: 1px solid #ccc;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    min-width: 480px;
    min-height: 213px;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    max-width: 1000px;
    text-align: center;
    font-size: 20px;
    font-weight: 200; }
    .container .content .closeButton {
      position: absolute;
      top: 16px;
      right: 17px;
      font-size: 18px;
      color: rgba(35, 31, 32, 0.5);
      background: none;
      border: none;
      cursor: pointer; }
    .container .content .title {
      text-rendering: optimizeLegibility;
      -webkit-font-feature-settings: "kern";
              font-feature-settings: "kern";
      -webkit-font-kerning: normal;
              font-kerning: normal;
      color: #333333;
      font-style: normal;
      font-size: 30px;
      text-align: center;
      margin-top: -25px; }
    .container .content .footer {
      background: #fff;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      margin-top: 30px; }
      .container .content .footer button {
        margin-left: 15px; }
