/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container .buttonContainer a[role*="button"], .container .buttonContainer a[role*="button"]:active, .container .buttonContainer a[role*="button"]:focus {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.container .buttonContainer a[role*="button"], .container .buttonContainer a[role*="button"]:active, .container .buttonContainer a[role*="button"]:focus {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .container .buttonContainer a:hover[role*="button"] {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .container .buttonContainer a:disabled[role*="button"] {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-top: 18px;
  -ms-flex-pack: end;
      justify-content: flex-end; }
  .container .freemiumInfo {
    -ms-flex: 1 1;
        flex: 1 1; }
  .container .buttonContainer {
    min-width: 150px; }
    .container .buttonContainer a[role*="button"] {
      font-family: inherit;
      line-height: 25px;
      min-width: 150px;
      font-size: 14px !important;
      height: 30px; }
      .container .buttonContainer a[role*="button"]:active, .container .buttonContainer a[role*="button"]:focus {
        font-family: inherit;
        line-height: 25px; }
  .container .status {
    -ms-flex: 1 1;
        flex: 1 1; }
  .container .link {
    color: white; }
