/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.buttonContainer button[role*="button"] {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.buttonContainer button[role*="button"] {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .buttonContainer button:hover[role*="button"] {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .buttonContainer button:disabled[role*="button"] {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.container :global(.ui.search .prompt) {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .container :global(.ui.search .prompt)::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .container :-moz-placeholder:global(.ui.search .prompt) {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .container :global(.ui.search .prompt)::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .container :-ms-input-placeholder:global(.ui.search .prompt) {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .container :focus:global(.ui.search .prompt) {
    outline: none;
    border-color: #4b45da; }
  .container :disabled:global(.ui.search .prompt) {
    background-color: #dbdcdd; }

.container :global(.ui.search .prompt) {
  font-size: 14px;
  height: 30px;
  width: 200px; }

.container {
  min-height: 80px !important;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  .container .searchSection {
    -ms-flex: 300px 1;
        flex: 300px 1; }
  .container .toolSection {
    -ms-flex: 650px 1 !important;
        flex: 650px 1 !important;
    min-width: 600px;
    margin-right: 10px; }
  .container .inputGroup {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex; }
    .container .inputGroup label {
      color: #4b4f54;
      margin: 0;
      padding: 0 8px 0 0; }
    .container .inputGroup .input {
      height: 30px;
      min-width: 120px !important; }
  .container :global(.ui.icon.input > input) {
    padding: 0.5em !important; }
  .container :global(.ui.search .prompt) {
    width: 300px; }
    .container :global(.ui.search .prompt):hover {
      background-color: #ffffff; }
    .container :global(.ui.search .prompt):focus {
      border-bottom-width: 1px; }

.iconButton {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  color: #4b45da;
  border: none;
  margin-left: -11px; }
  .iconButton:disabled {
    opacity: 0.5; }
  .iconButton > i {
    font-size: 18px; }

.buttonContainer {
  position: relative; }
  .buttonContainer button[role*="button"] {
    font-size: 14px !important;
    height: 30px;
    min-width: 120px; }
