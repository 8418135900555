/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.nameForm {
  -ms-flex: 1 1;
      flex: 1 1;
  margin-left: -2px; }

.formContainer .errorMessages {
  margin: 5px 0;
  padding: 0 0 0 8px;
  font-size: 13px;
  color: #c10000; }
  .formContainer .errorMessages li {
    list-style: none; }

.formContainer .inputContainer.nonEditable {
  cursor: default; }

.formContainer .inputContainer .inputWithButtons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: relative; }
  .formContainer .inputContainer .inputWithButtons .buttonContainer {
    position: absolute;
    right: 0;
    top: 10px;
    min-width: 54px; }
  .formContainer .inputContainer .inputWithButtons i {
    font-size: 12px !important; }
  .formContainer .inputContainer .inputWithButtons input {
    font-size: 30px !important;
    -ms-flex: 1 1;
        flex: 1 1;
    margin-right: 54px;
    padding: 5px 8px 5px 7px;
    border: 1px solid white; }
    .formContainer .inputContainer .inputWithButtons input:hover {
      background-color: rgba(216, 216, 216, 0.5); }
    .formContainer .inputContainer .inputWithButtons input:focus {
      background-color: white;
      border: 1px solid #4b45da;
      border-radius: 4px; }
