*:not(:global(.absolute)):not(:global(.stacked)) + .container {
  margin-left: 0.4em; }

.container {
  display: inline-block; }
  .container.rotate90 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg); }
  .container.rotate180 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg); }
  .container.rotate270 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg); }
  .container + *:not(:global(.absolute)):not(:global(.stacked)) {
    margin-left: 0.4em; }
