/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.inputIconButtons {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  height: 30px; }
  .inputIconButtons > * {
    cursor: pointer;
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: inherit;
    margin-left: 5px;
    padding: 0 5px; }
  .inputIconButtons i[class*="icon"] {
    color: #4b4f54;
    font-size: 11px;
    margin: 0;
    opacity: 0.8; }

/**
  Generic styles used by multiple components
 */
.settingValue {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  height: 34px;
  padding: 3px 12px;
  line-height: 1.42857143;
  background-image: none;
  -webkit-box-shadow: none;
          box-shadow: none; }

.fieldRow {
  display: -ms-flexbox;
  display: flex; }

/** Error message for use under fields with smaller right margin */
.inputIconButtons :global(.ui.loader) {
  margin: 0 5px 0 10px !important; }

/** Caret for sorting on table headers */
div[class*="-sort-asc"]:after {
  font-size: 6px; }

div[class*="-sort-desc"]:after {
  font-size: 6px; }
