/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }
  .container .tabsContainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    -ms-flex-direction: column;
        flex-direction: column; }

.flexCol {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.headerList {
  list-style-type: none;
  margin: 0 0 8px;
  padding: 0; }
  .headerList > li {
    display: inline-block; }
  .headerList > li + li {
    display: inline-block;
    border-left: 1px solid #4b4f54;
    height: 20px;
    padding-left: 16px;
    margin-left: 16px;
    line-height: 20px; }

.maintenanceTabContainer {
  position: relative; }
  .maintenanceTabContainer .badge {
    color: #ff9e16;
    position: absolute;
    top: -7px;
    right: -14px;
    font-size: 14px; }
