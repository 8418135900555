/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  background: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .container .uploaderContainer > span {
    font-family: NimbusSans-Medium, sans-serif;
    font-size: 16px; }
  .container .dropzone {
    border-width: 2px;
    border-color: #4b45da;
    border-style: dashed;
    border-radius: 5px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    cursor: pointer;
    background-color: rgba(245, 245, 245, 0.9);
    height: 226px;
    width: 394px;
    margin-bottom: 30px; }
    .container .dropzone span {
      font-family: NimbusSans-Medium, sans-serif;
      font-size: 16px; }
    .container .dropzone .icon {
      font-size: 40px;
      color: #757575; }
    .container .dropzone .browse {
      margin-top: 12px; }
      .container .dropzone .browse span {
        color: #00afaa;
        font-size: 13px; }
    .container .dropzone img {
      width: 384px;
      height: 216px; }
  .container .error {
    display: block;
    margin-top: 11px;
    max-width: 390px; }
    .container .error span {
      font-size: 13px;
      color: #c10000;
      font-family: NimbusSans; }
  .container .helperText {
    margin-top: 8px;
    font-family: NimbusSans-Light, sans-serif;
    font-size: 13px;
    width: 270px; }
