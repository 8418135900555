.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  color: #555;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }
  .container .header {
    font-size: 28px;
    margin: 0 0 10px 10px;
    font-family: NimbusSans-Light, sans-serif; }
  .container .tabsContainer {
    -ms-flex-positive: 1;
        flex-grow: 1;
    display: -ms-flexbox;
    display: flex; }
    .container .tabsContainer.production div[class="rt-td InfiniteTable-tableCell"]:first-of-type {
      margin-left: 0; }
    .container .tabsContainer > div {
      -ms-flex-positive: 1;
          flex-grow: 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
    .container .tabsContainer .editButton {
      -ms-flex-align: center;
          align-items: center;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      -ms-flex-pack: start;
          justify-content: flex-start;
      width: 100%; }
      .container .tabsContainer .editButton i {
        margin: 0; }
  .container .content {
    background-color: #fff;
    -ms-flex: 1 1;
        flex: 1 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    padding: 15px 10px;
    -webkit-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
            box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
    margin-bottom: 15px;
    min-width: 500px;
    margin-right: 15px;
    overflow: hidden;
    width: 100%;
    -ms-flex-pack: center;
        justify-content: center; }
    .container .content .buttonBar {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between;
      margin-bottom: 15px; }
      .container .content .buttonBar button {
        margin-left: 10px; }
