/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.toastContainer {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
  position: relative;
  overflow: visible !important;
  cursor: default;
  margin-top: 5px; }
  .toastContainer:before {
    position: absolute;
    top: -9px;
    right: 79px;
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 10px solid white; }

.container {
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #ffffff; }

.iconContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 56px;
      flex: 0 0 56px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 26px;
  color: #4b45da; }

.failureContainer {
  color: #ff9e16;
  border-color: #ff9e16; }

.successContainer {
  color: #00c18b; }

.messageContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex: 1 1;
      flex: 1 1;
  font-size: 11px;
  line-height: 1.6;
  color: #4b4f54; }

.fileReadyButton {
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  -ms-flex: 1 1;
      flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.closeButton {
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  padding: 10px;
  color: #4b4f54; }
  .closeButton i {
    font-size: 10px; }

.hideTransition {
  display: none; }
