.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0; }
  .container .dataContainer {
    -ms-flex: 1 1;
        flex: 1 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 10px; }
    .container .dataContainer:first-child {
      max-width: 100px; }
  .container .previewImage {
    max-height: 42px;
    max-width: 75px; }
  .container .title {
    color: #3cbfae;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .container > div {
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .container .dateTime > span {
    margin-right: 10px; }
