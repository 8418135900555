/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  display: -ms-flexbox;
  display: flex;
  width: 480px;
  color: #231f20; }
  .container .header {
    font-size: 30px;
    border-bottom: 2px solid #d8d8d8;
    padding-bottom: 10px;
    margin-bottom: 30px; }
  .container .body {
    padding: 0 30px 27px; }
    .container .body .subTitle {
      margin-bottom: 20px;
      font-size: 16px; }
    .container .body .list {
      margin-bottom: 8px; }
      .container .body .list div[class="ReactTable ListViewComponent-container ListViewComponent-smallTable"] {
        min-height: 69px;
        max-height: 150px;
        margin-left: 0;
        margin-right: 0; }
      .container .body .list div[class="rt-thead -header"] {
        height: 30px; }
      .container .body .list div[class="rt-tr"] {
        height: 30px; }
      .container .body .list div[role="columnheader"] {
        height: 30px; }
      .container .body .list div[class='rt-tbody'] {
        overflow: auto;
        max-height: 118px; }
    .container .body .message {
      font-size: 13px;
      padding-top: 18px; }
      .container .body .message strong {
        font-family: NimbusSans-Bold, sans-serif; }
