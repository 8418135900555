/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  -ms-flex-align: end;
      align-items: flex-end;
  border-radius: 4px;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1;
      flex: 1 1;
  font-size: 13px;
  height: 74px;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  line-height: 1;
  margin: 0 8px 0 0;
  min-width: 200px;
  overflow: hidden;
  padding: 0 8px;
  position: relative; }
  .container:last-child {
    margin-right: 0; }
  .container.calls {
    background-color: #4c87b9; }
  .container.minutes {
    background-color: #d05454; }
  .container.active {
    background-color: #f2784b; }
  .container.avg {
    background-color: #8877a9; }
  .container .dataPoint {
    font-family: NimbusSans-Bold, sans-serif;
    font-size: 24px; }
  .container .icon {
    bottom: -8px;
    font-size: 56px;
    left: -11px;
    margin: 0;
    opacity: 0.2;
    position: absolute; }
