.editButton {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-pack: start;
      justify-content: flex-start;
  width: 100%; }
  .editButton i {
    margin: 0; }
