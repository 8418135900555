.paginationContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  list-style-type: none;
  padding: 0 10px;
  margin: 0;
  -o-object-fit: contain;
     object-fit: contain; }
  .paginationContainer li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    min-width: 35px;
    min-height: 35px;
    cursor: pointer;
    outline: none !important; }
    .paginationContainer li a {
      color: #3cbfae;
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 10px 15px;
      font-size: 14px;
      outline: none !important; }
  .paginationContainer .activePage a {
    color: #4b4f54;
    cursor: not-allowed; }
  .paginationContainer .breakStyle {
    min-width: 15px;
    background-color: #e3e3e3;
    padding: 10px 15px;
    color: #999999;
    cursor: not-allowed; }
  .paginationContainer .disabled a {
    color: #4b4f54;
    cursor: not-allowed; }
  .paginationContainer .next {
    border-radius: 0 4px 4px 0; }
  .paginationContainer .prev {
    border-radius: 4px 0 0 4px; }
