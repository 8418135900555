.container {
  -ms-flex: 1 1;
      flex: 1 1;
  height: 100%;
  margin: 0 8px;
  max-height: 100%; }
  .container div[class~='rt-th']:first-of-type {
    margin-left: 1vw; }
  .container div[class="rt-tr-group"] {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto; }
    .container div[class="rt-tr-group"]:hover {
      background-color: rgba(0, 0, 0, 0.05); }
    .container div[class="rt-tr-group"] div[class="rt-td ListViewComponent-tableCell rt-expandable"] {
      margin-top: -1px;
      padding-top: 0; }
    .container div[class="rt-tr-group"] div[class="rt-expander"]:after {
      border-top: none;
      border-left: none;
      border-right: 2px solid #4b45da;
      border-bottom: 2px solid #4b45da;
      width: 7px;
      height: 7px;
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg); }
    .container div[class="rt-tr-group"] div[class="rt-expander -open"]:after {
      border-top: none;
      border-left: none;
      border-right: 2px solid #4b45da;
      border-bottom: 2px solid #4b45da;
      width: 7px;
      height: 7px;
      -webkit-transform: rotate(-135deg);
          -ms-transform: rotate(-135deg);
              transform: rotate(-135deg); }
  .container div[class='rt-noData'] {
    background-color: transparent; }

/**
This aligns no results panel on smaller, dashboard tables:
 */
.smallTable {
  min-height: 212px; }
  .smallTable div[class='rt-table'] div[class='rt-tbody'] {
    overflow: hidden; }

.tableHeaderTitle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #000000;
  font-family: NimbusSans, sans-serif; }
  .tableHeaderTitle:first-of-type {
    margin-left: 1vw; }

.tableCell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-family: NimbusSans, sans-serif; }
  .tableCell:first-of-type {
    margin-left: 1vw; }
