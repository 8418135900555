/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container .alexaAuthentication .buttonContainer button[role*="button"], .container .alexaAuthentication .buttonContainer button[role*="button"]:active, .container .alexaAuthentication .buttonContainer button[role*="button"]:focus {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.container .alexaAuthentication .buttonContainer button[role*="button"], .container .alexaAuthentication .buttonContainer button[role*="button"]:active, .container .alexaAuthentication .buttonContainer button[role*="button"]:focus {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .container .alexaAuthentication .buttonContainer button:hover[role*="button"] {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .container .alexaAuthentication .buttonContainer button:disabled[role*="button"] {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.container {
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 8px 8px;
  padding: 16px 25px; }
  .container.loadingContainer {
    min-height: 222px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
  .container .settingsTitle {
    font-size: 16px;
    color: #4b4f54;
    font-weight: normal; }
  .container .instructions {
    font-weight: 200;
    padding: 0;
    margin-bottom: 20px;
    line-height: 16px; }
  .container .alexaSettings {
    display: -ms-flexbox;
    display: flex; }
  .container .alexaFormInput {
    height: 30px;
    width: 400px;
    background-color: white;
    border-radius: 4px !important;
    border: 1px solid #a5a7aa;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #4b4f54;
    font-weight: 200;
    min-height: 0;
    outline: none;
    padding: 0 0.5em; }
    .container .alexaFormInput input {
      border-bottom: none;
      border-bottom: none;
      padding: 0 !important; }
  .container .alexaInputs {
    -ms-flex: 1 1;
        flex: 1 1; }
    .container .alexaInputs .alexaRow {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      margin-bottom: 15px; }
      .container .alexaInputs .alexaRow span {
        min-width: 100px; }
  .container .alexaAuthentication {
    width: 200px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column; }
    .container .alexaAuthentication .buttonContainer button[role*="button"] {
      min-width: 140px;
      min-height: 30px; }
      .container .alexaAuthentication .buttonContainer button[role*="button"] div[class*="loader"]:after {
        border-color: white transparent transparent; }
      .container .alexaAuthentication .buttonContainer button[role*="button"] div[class*="loader"]:before {
        border: 0.2em solid rgba(0, 0, 0, 0.5); }
  .container .disconnectRow {
    max-width: 504px;
    min-height: 14px;
    display: -ms-flexbox;
    display: flex;
    padding-left: 100px; }
    .container .disconnectRow .authState {
      margin-left: 3px; }
    .container .disconnectRow .disconnect {
      background-color: white;
      border: none;
      color: #00afaa;
      outline: none;
      padding: 0;
      margin: 0;
      height: 14px;
      justify-self: flex-end;
      -ms-flex: 1 1;
          flex: 1 1;
      text-align: right;
      min-width: 90px; }
      .container .disconnectRow .disconnect:hover, .container .disconnectRow .disconnect:focus {
        background-color: white;
        border: none;
        color: #00afaa;
        outline: none; }
  .container .authMessage {
    min-height: 45px;
    font-weight: bold;
    padding: 0 24px;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .container .authMessage.authSuccess {
      color: #00c18b; }
    .container .authMessage.authError {
      color: #c10000; }
