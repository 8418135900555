/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1;
      flex: 1 1;
  margin: 0 8px 8px 0;
  min-height: 248px;
  min-width: 500px;
  overflow: hidden;
  padding: 22px 25px 10px;
  width: 100%; }
  .container.wide {
    -ms-flex: 2 1 auto;
        flex: 2 1 auto; }
  .container .header {
    font-size: 16px;
    text-align: center;
    margin: 0 0 5px 0; }
  .container div[class*="ReactTable"] div[class="rt-table"] {
    min-height: 150px; }
  .container .chart {
    -ms-flex: 1 1;
        flex: 1 1;
    width: 100%;
    display: -ms-flexbox;
    display: flex; }
    .container .chart div {
      width: 100%; }
  .container .footer {
    font-size: 13px;
    padding: 14px 0 0;
    text-align: right; }
    .container .footer a {
      color: #4b45da !important;
      cursor: pointer;
      text-decoration: none;
      text-shadow: none; }
      .container .footer a:hover {
        color: #2a8579 !important; }
