.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }
  .container .tabsContainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    -ms-flex-direction: column;
        flex-direction: column; }

.flexCol {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.separator {
  color: #4b4f54;
  height: 20px;
  margin-left: 16px;
  margin-right: 16px;
  line-height: 20px;
  font-size: 17px; }
