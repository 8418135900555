.container {
  width: 100%; }

.clickableElement {
  color: #3cbfae; }

.clickableElement:hover {
  cursor: pointer;
  color: #2a8579; }

.clickableElement:active {
  background-color: transparent; }
