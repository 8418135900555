/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.buttonContainer button[role*="button"], .buttonContainerLog button[role*="button"], .buttonContainerLog button[role*="button"]:active, .buttonContainerLog button[role*="button"]:focus {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.buttonContainer button[role*="button"], .buttonContainerLog button[role*="button"], .buttonContainerLog button[role*="button"]:active, .buttonContainerLog button[role*="button"]:focus {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .buttonContainer button:hover[role*="button"], .buttonContainerLog button:hover[role*="button"] {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .buttonContainer button:disabled[role*="button"], .buttonContainerLog button:disabled[role*="button"] {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.buttonContainer button[role*="button"], .buttonContainerLog button[role*="button"], .buttonContainerLog button[role*="button"]:active, .buttonContainerLog button[role*="button"]:focus {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.buttonContainer button[role*="button"], .buttonContainerLog button[role*="button"], .buttonContainerLog button[role*="button"]:active, .buttonContainerLog button[role*="button"]:focus {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .buttonContainer button:hover[role*="button"], .buttonContainerLog button:hover[role*="button"] {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .buttonContainer button:disabled[role*="button"], .buttonContainerLog button:disabled[role*="button"] {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.error {
  color: #c10000;
  font-size: 13px;
  font-style: italic;
  left: 0;
  line-height: initial;
  margin-top: 5px;
  max-width: 100%;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap; }

.container {
  width: 100%;
  height: 100%;
  padding-top: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: auto; }

.pane {
  margin: 4px 9px;
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  min-width: 500px;
  -ms-flex-negative: 0;
      flex-shrink: 0; }

.contentContainer {
  margin: 17px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.paneHeader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center; }

.paneTitle {
  font-size: 18px;
  font-family: NimbusSans-Light, sans-serif;
  color: #4b4f54;
  font-weight: 300; }

.paneHeaderStatus, .paneHeaderStatusItalic {
  font-size: 10px;
  opacity: 0.5; }

.paneHeaderStatusItalic {
  font-style: italic; }

.errorCountContainer {
  margin-top: 8px; }

.smallGray {
  font-size: 10px;
  opacity: 0.5; }

.detailsDescription {
  margin-top: 16px;
  font-size: 10px;
  color: black; }

.italic {
  font-style: italic; }

.flexColCenter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }

.paneBody {
  margin-top: 18px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 8px;
  position: relative;
  width: 100%; }

.textContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  font-size: 12px;
  font-family: NimbusSans-Light, sans-serif;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  white-space: pre-wrap;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none; }

.error {
  top: 20px;
  font-size: 12px; }

.buttonContainer button[role*="button"] {
  font-size: 14px !important;
  height: 30px;
  min-width: 120px; }

.heading {
  font-size: 10px;
  font-weight: bold; }

.healthContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  width: 100%; }

.healthStatusCol {
  font-size: 12px;
  -ms-flex: 0 0 85px;
      flex: 0 0 85px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  margin-right: 13px; }

.healthIconContainer {
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 14px;
  font-size: 28px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.healthDetailsCol {
  -ms-flex: 1 1;
      flex: 1 1; }

.tableContainer {
  width: 100%; }

.table {
  width: 100%;
  border-left: solid 1px rgba(0, 0, 0, 0.15); }
  .table tr {
    border-bottom: solid 1px rgba(0, 0, 0, 0.15); }
  .table tr:last-child {
    border-bottom: none; }
  .table th {
    padding: 0 10px; }
  .table td {
    padding: 16px 10px;
    vertical-align: top; }

.itemColumn {
  width: 145px; }

.itemContainer {
  font-size: 12px;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2px; }

.itemIconStyle {
  font-size: 12px;
  margin: 0 10px;
  color: #4b45da; }

.statusColumn {
  width: 70px;
  text-align: center; }

.statusIconContainer {
  text-align: center;
  font-size: 16px; }

.dateColumn {
  width: 130px; }

.upgradeIcon {
  font-size: 12px;
  margin-right: 6px; }

.green {
  color: #00c18b;
  border-color: #00c18b; }

.orange {
  color: #ff9e16;
  border-color: #ff9e16; }

.red {
  color: #c10000;
  border-color: #c10000; }

.buttonNotificationText {
  margin-top: 6px;
  width: 150px;
  text-align: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  display: -ms-flexbox;
  display: flex;
  font-size: 10px; }

.buttonSuccessNotificationText {
  margin-top: 6px;
  width: 150px;
  text-align: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  display: -ms-flexbox;
  display: flex;
  font-size: 10px;
  color: #00c18b; }

.buttonErrorNotificationText {
  margin-top: 6px;
  width: 150px;
  text-align: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  display: -ms-flexbox;
  display: flex;
  font-size: 10px;
  color: #c10000; }

.shortContainer {
  text-align: left;
  padding-right: 80px;
  max-width: 830px; }

.buttonContainerLog {
  -ms-flex: 150px 0;
      flex: 150px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: end;
      align-items: flex-end; }
  .buttonContainerLog button[role*="button"] {
    font-size: 14px !important;
    height: 30px;
    width: 120px; }
