/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container .registerButton, .container .registerButton button[role*="button"], .container .registerButton button[role*="button"]:active, .container .registerButton button[role*="button"]:focus {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.container .registerButton, .container .registerButton button[role*="button"], .container .registerButton button[role*="button"]:active, .container .registerButton button[role*="button"]:focus {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .container .registerButton:hover, .container .registerButton button:hover[role*="button"] {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .container .registerButton:disabled, .container .registerButton button:disabled[role*="button"] {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.container {
  background-color: white;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 17px;
  display: -ms-flexbox;
  display: flex; }
  .container .settingsTitle {
    font-size: 18px;
    line-height: 24px;
    font-family: NimbusSans-Light, sans-serif;
    color: #4b4f54;
    font-weight: 300; }
  .container .instructions {
    font-size: 12px;
    font-family: NimbusSans-Light, sans-serif;
    font-weight: 300;
    padding: 10px 0 18px;
    line-height: 16px;
    -ms-flex: 1 1;
        flex: 1 1; }
  .container .alexaSettings {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
        align-items: flex-end;
    margin-bottom: 8px; }
  .container .alexaRegistration {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
        align-items: flex-end;
    -ms-flex-direction: column;
        flex-direction: column;
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap; }
  .container .alexaInstructions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1 1;
        flex: 1 1; }
  .container .authSuccess {
    min-height: 40px;
    font-weight: bold;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    color: #00c18b; }
  .container .registerButton {
    min-width: 140px;
    min-height: 30px;
    font-weight: 500 !important;
    line-height: 11px !important; }
    .container .registerButton button[role*="button"] div[class*="loader"]:after {
      border-color: white transparent transparent; }
    .container .registerButton button[role*="button"] div[class*="loader"]:before {
      border: 0.2em solid rgba(0, 0, 0, 0.5); }
