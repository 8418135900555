/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container .content .footer .btnPrimary, .container .content .footer .btnSecondary {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.container .content .footer .btnPrimary {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .container .content .footer .btnPrimary:hover {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .container .content .footer .btnPrimary:disabled {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.container .content .footer .btnSecondary {
  background-color: white;
  border: 1px solid #4b4f54;
  color: #4b4f54; }
  .container .content .footer .btnSecondary:hover {
    background-color: white;
    border-color: black;
    color: black; }
  .container .content .footer .btnSecondary:disabled {
    background-color: white;
    border-color: #a5a7aa;
    color: #a5a7aa;
    opacity: 1 !important; }

.container {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }
  .container .content {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #ccc;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    font-size: 20px;
    font-weight: 200;
    -ms-flex-pack: justify;
        justify-content: space-between;
    max-width: 1000px;
    min-height: 184px;
    min-width: 480px;
    padding: 30px 0;
    position: relative;
    text-align: center; }
    .container .content .closeButton {
      position: absolute;
      top: 14px;
      right: 16px;
      font-size: 18px;
      color: rgba(35, 31, 32, 0.5);
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
      height: 16px;
      width: 16px; }
      .container .content .closeButton i {
        font-size: 16px;
        padding: 0;
        margin: 0;
        width: 16px;
        height: 16px; }
    .container .content .title {
      text-align: center;
      font-size: 28px;
      color: #231f20;
      margin-top: -8px;
      margin-bottom: 0;
      min-height: 12px; }
    .container .content .subTitle, .container .content .subTitleSmall {
      text-align: center;
      min-height: 16px;
      overflow-wrap: break-word;
      word-break: break-word;
      word-wrap: break-word;
      font-size: 16px;
      color: #4b4f54;
      padding: 0 24px; }
    .container .content .subTitleSmall {
      font-size: 13px; }
    .container .content .contextContainer {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-pack: center;
          justify-content: center;
      padding-top: 12px;
      padding-bottom: 30px; }
      .container .content .contextContainer .contextLabel {
        display: -ms-flexbox;
        display: flex;
        font-weight: normal;
        font-size: 13px;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
        -ms-flex-pack: end;
            justify-content: flex-end;
        padding-right: 5px; }
      .container .content .contextContainer .contextText {
        display: -ms-flexbox;
        display: flex;
        font-size: 13px;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    .container .content .childrenContainer {
      font-size: 16px;
      color: #4b4f54; }
    .container .content .errorMessage {
      font-size: 10px;
      color: #c10000;
      position: absolute;
      bottom: 8px;
      width: 100%; }
    .container .content .footer {
      padding-top: 8px;
      background: #fff;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center; }
      .container .content .footer button {
        font-size: 17px !important;
        height: auto;
        min-height: 40px;
        min-width: 150px; }
        .container .content .footer button:not(:first-child) {
          margin-left: 24px; }
