/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.inputGroup .input {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .inputGroup .input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .inputGroup .input:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .inputGroup .input::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .inputGroup .input:-ms-input-placeholder {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .inputGroup .input:focus {
    outline: none;
    border-color: #4b45da; }
  .inputGroup .input:disabled {
    background-color: #dbdcdd; }

.connectContentContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: 16px; }

.connectContentColumn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.connectContentRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.connectButtonContainer {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: end;
      align-items: flex-end;
  -ms-flex: 0 0 150px;
      flex: 0 0 150px;
  margin-bottom: 15px; }

.apiMessage {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-style: italic; }

.apiSuccess {
  color: #00c18b;
  width: 140px;
  text-align: center; }

.apiError {
  color: #c10000;
  width: 400px;
  text-align: right; }

.inputGroup {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: start;
      justify-content: flex-start;
  margin-top: 16px;
  /* note: the label is not vertically aligned to the center of the adjacent input so that if the label wraps, the
   * first line of the label is still aligned with the input correctly */ }
  .inputGroup label {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #4b4f54;
    font-size: 13px;
    margin-bottom: 0;
    padding: 0 8px 0 0;
    width: 125px; }
  .inputGroup .input {
    font-size: 14px;
    height: 30px;
    width: 300px; }
    .inputGroup .input > :global(.default.text) {
      color: #a1a2a6 !important;
      font-family: NimbusSans-Light, sans-serif; }
  .inputGroup .tooltipButtonIcon {
    color: #4b45da;
    font-size: 17px;
    cursor: pointer; }

.tooltipContainer {
  margin: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  text-align: left;
  font-weight: 100; }
