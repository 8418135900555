/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container .streamingLink dd .buttonContainer .button {
  background: none;
  border: none;
  color: #4b45da;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 17px !important;
  padding: 2px;
  position: relative;
  z-index: 100; }
  .container .streamingLink dd .buttonContainer .button:hover {
    color: #290088; }
  .container .streamingLink dd .buttonContainer .button:disabled {
    opacity: 0.5; }

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important; }
  .container.loadingContainer {
    min-height: 100px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
  .container .toggleLabelLarge {
    font-size: 16px !important;
    font-weight: 500;
    line-height: 1.5;
    padding-top: 0; }
  .container .toggleLabelMedium {
    font-size: 14px !important; }
  .container .shortGroup {
    margin-bottom: 12px !important; }
  .container .streamingLink {
    font-size: 13px;
    margin: 0;
    text-align: left;
    width: 100%; }
    .container .streamingLink dt {
      font-weight: normal; }
    .container .streamingLink dd {
      -ms-flex-align: center;
          align-items: center;
      display: -ms-flexbox;
      display: flex;
      font-weight: 200;
      margin: 0; }
      .container .streamingLink dd a, .container .streamingLink dd span {
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
        overflow: hidden;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        white-space: nowrap; }
      .container .streamingLink dd .buttonContainer {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
        .container .streamingLink dd .buttonContainer > * {
          margin: 0 0 0 14px; }
  .container .embedDdContainer {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
    min-height: 94px; }
  .container .embedCodeContainer {
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px; }
  .container .footnote {
    color: #c10000; }
