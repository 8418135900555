.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 20px 15px;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }
  .container .header {
    font-size: 28px;
    margin: 0 0 10px 10px;
    font-family: NimbusSans-Light, sans-serif; }
  .container .chartsContainer {
    -ms-flex: 1 1 100%;
        flex: 1 1 100%; }
    .container .chartsContainer > div {
      -webkit-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
              box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75); }

.expiresSoonContainer {
  margin-top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 60px;
  height: 16px;
  border-radius: 4px;
  background-color: #ed6b75; }

.expiresSoonText {
  font-family: NimbusSans, sans-serif;
  font-size: 10px;
  text-align: center;
  color: #ffffff; }
