.noDataContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  position: absolute;
  top: 50px; }
  .noDataContainer .noDataContent {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    max-width: 360px;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    font-size: 20px;
    line-height: 23px;
    font-family: NimbusSans-Light, sans-serif;
    -ms-flex-item-align: center;
        align-self: center;
    text-align: center;
    margin-top: 50px; }
    .noDataContainer .noDataContent .iconContainer {
      color: #4b4f54;
      font-size: 45px; }
