/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container .formGroup input {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .container .formGroup input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .container .formGroup input:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .container .formGroup input::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .container .formGroup input:-ms-input-placeholder {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .container .formGroup input:focus {
    outline: none;
    border-color: #4b45da; }
  .container .formGroup input:disabled {
    background-color: #dbdcdd; }

.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  letter-spacing: normal;
  line-height: 1.25; }

.container .formGroup {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row; }

.container .formGroup label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  font-size: 16px;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  height: 30px;
  margin: 3px 10px 2px 0; }

.container .formGroup input {
  font-size: 14px;
  height: 30px;
  width: 200px; }

.container .formGroup .inputError input {
  border: solid 1px red;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.container .formGroup .textField {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  height: 30px;
  letter-spacing: normal;
  color: #000000;
  margin: 3px 10px 2px 0; }

.container {
  width: 480px;
  height: auto;
  max-height: 322px; }
  .container .child {
    width: 416px;
    margin-left: 30px;
    margin-bottom: 13px;
    border-bottom: 1px solid #d9d9d9;
    padding: 0 10px 16px 80px; }
  .container .formGroup .textField {
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    margin-right: 0; }
  .container .formGroup label {
    width: 137px; }
  .container .formGroup label,
  .container .formGroup input {
    font-size: 14px;
    color: #000000; }
