/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.formGroup .input, .formGroup .textarea, :global(.ui.selection.dropdown) {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .formGroup .input::-webkit-input-placeholder, .formGroup .textarea::-webkit-input-placeholder, :global(.ui.selection.dropdown)::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .formGroup .input:-moz-placeholder, .formGroup .textarea:-moz-placeholder, :-moz-placeholder:global(.ui.selection.dropdown) {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .formGroup .input::-moz-placeholder, .formGroup .textarea::-moz-placeholder, :global(.ui.selection.dropdown)::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .formGroup .input:-ms-input-placeholder, .formGroup .textarea:-ms-input-placeholder, :-ms-input-placeholder:global(.ui.selection.dropdown) {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .formGroup .input:focus, .formGroup .textarea:focus, :focus:global(.ui.selection.dropdown) {
    outline: none;
    border-color: #4b45da; }
  .formGroup .input:disabled, .formGroup .textarea:disabled, :disabled:global(.ui.selection.dropdown) {
    background-color: #dbdcdd; }

.formGroup {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row; }

.formGroup label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  font-size: 16px;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  height: 30px;
  margin: 3px 10px 2px 0; }

.formGroup .input {
  font-size: 14px;
  height: 30px;
  width: 200px; }

.formGroup .textarea {
  padding-bottom: 4px;
  padding-top: 4px;
  width: 100%; }

.helpMessage {
  font-style: italic;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  margin: auto;
  -ms-flex-pack: center;
      justify-content: center; }

.messageContainer .error {
  color: #c10000;
  font-size: 13px;
  font-style: italic;
  left: 0;
  line-height: initial;
  margin-top: 5px;
  max-width: 100%;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap; }

.formGroup {
  width: 100%;
  margin-bottom: 21px; }
  .formGroup div:first-of-type {
    width: 100%; }
  .formGroup textarea {
    border: 1px solid #4b45da; }
  .formGroup label {
    max-width: 157px;
    font-size: 13px;
    font-weight: 500; }
  .formGroup input[type='checkbox'] {
    margin-top: auto;
    margin-bottom: auto; }
  .formGroup .input {
    border: solid 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 320px; }
  .formGroup .textarea {
    height: 102px;
    max-width: 320px; }

.radioGroup {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.messageContainer {
  color: #4b4f54;
  display: -ms-flexbox;
  display: flex;
  font-size: 10px;
  line-height: initial;
  position: relative;
  text-align: left; }
  .messageContainer .error.tightError {
    margin-top: 0; }

:global(.ui.selection.dropdown) {
  padding-left: 8px !important; }
  :global(.ui.selection.dropdown):hover {
    border: solid 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: none;
            box-shadow: none; }
  :global(.ui.selection.dropdown):global(.active) {
    border: 1px solid #4b45da;
    -webkit-box-shadow: none;
            box-shadow: none; }
    :global(.ui.selection.dropdown):global(.active):hover {
      -webkit-box-shadow: none;
              box-shadow: none; }
  :global(.ui.selection.dropdown) > input ~ :global(.default.text) {
    color: #a1a2a6 !important;
    font-family: NimbusSans-Light, sans-serif; }
  :global(.ui.selection.dropdown) > :global(div.text) {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    left: auto;
    top: auto; }
  :global(.ui.selection.dropdown) > :global(i.dropdown.icon) {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 100%; }
  :global(.ui.selection.dropdown) > :global(.menu) {
    border-color: #4b45da;
    border-style: solid;
    border-width: 0 1px 1px;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    outline: none; }
    :global(.ui.selection.dropdown) > :global(.menu) > :global(.item) {
      border-color: rgba(0, 0, 0, 0.1);
      border-style: solid;
      border-width: 1px 0 0;
      font-size: inherit;
      line-height: inherit; }
      :global(.ui.selection.dropdown) > :global(.menu) > :global(.item):global(.active), :global(.ui.selection.dropdown) > :global(.menu) > :global(.item):global(.selected) {
        background-color: rgba(0, 0, 0, 0.05);
        color: inherit; }
      :global(.ui.selection.dropdown) > :global(.menu) > :global(.item):hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: inherit; }
