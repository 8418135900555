.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: start;
      align-items: flex-start;
  height: 100%;
  width: 400px; }

.item1 {
  padding-top: 20px; }

.item2 {
  padding-top: 20px; }

.item3 {
  padding-top: 20px; }
