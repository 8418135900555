/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.style input {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .style input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .style input:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .style input::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .style input:-ms-input-placeholder {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .style input:focus {
    outline: none;
    border-color: #4b45da; }
  .style input:disabled {
    background-color: #dbdcdd; }

.style input {
  font-size: 14px;
  height: 30px;
  width: 200px; }

.style {
  /* Elastic search drop-down results */ }
  .style input {
    font-family: NimbusSans-Light, sans-serif;
    font-size: 16px;
    color: #4b4f54;
    width: 50px;
    -ms-flex-item-align: stretch;
        align-self: stretch;
    -ms-flex-align: center;
        align-items: center;
    border: none !important; }
  .style div[class="results transition visible"] {
    width: 200px !important;
    overflow: hidden auto;
    max-height: 239px; }

.container {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cccccc;
  margin-top: 8px;
  min-height: 38px; }

.loaderContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 10px 0;
  width: 100%;
  min-height: 20px; }

.selectionsContainer {
  padding: 5px 15px 5px 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  font-size: 13px;
  max-height: 105px;
  overflow-y: auto; }

.resetButton {
  position: absolute;
  top: 8px;
  right: 5px;
  color: #4b45da;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer; }

.searchInput {
  height: 26px;
  -ms-flex: 1 1;
      flex: 1 1;
  border: none; }

.pill {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 4px;
  color: #231f20;
  border: 1px solid #4b45da;
  font-size: 13px;
  padding: 4px 8px;
  margin: 4px; }

.iconPillCancel {
  color: #231f20;
  margin-left: 8px;
  width: 8px;
  height: 8px;
  font-size: 9px;
  cursor: pointer; }

.searchResultsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  background-color: white;
  border: 1px solid #cccccc;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll; }

.searchResult {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 13px;
  text-align: left;
  padding: 10px 10px; }
  .searchResult:hover {
    background-color: #d9d9d9; }
