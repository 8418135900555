/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.formControl form .formGroup .input, .formControl form .formGroup .textarea {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .formControl form .formGroup .input::-webkit-input-placeholder, .formControl form .formGroup .textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .formControl form .formGroup .input:-moz-placeholder, .formControl form .formGroup .textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .formControl form .formGroup .input::-moz-placeholder, .formControl form .formGroup .textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .formControl form .formGroup .input:-ms-input-placeholder, .formControl form .formGroup .textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .formControl form .formGroup .input:focus, .formControl form .formGroup .textarea:focus {
    outline: none;
    border-color: #4b45da; }
  .formControl form .formGroup .input:disabled, .formControl form .formGroup .textarea:disabled {
    background-color: #dbdcdd; }

.formControl form .formGroup label {
  font-weight: normal;
  line-height: 1.2em;
  text-align: left;
  margin: 0;
  min-height: 30px;
  padding-right: 12px; }
  .formControl form .formGroup label.labelLarge {
    font-size: 16px;
    padding-top: 0.4em; }
  .formControl form .formGroup label.labelMedium {
    font-size: 14px;
    padding-top: 0.5em; }
  .formControl form .formGroup label.labelStrong {
    font-weight: 500; }

.formControl form .formGroup.hasValidation .inputMessages {
  color: #4b4f54;
  font-size: 13px;
  line-height: 1.5;
  list-style-type: none;
  margin: 1px 0 0;
  min-height: 1.5em;
  padding: 0;
  position: relative;
  text-align: left; }
  .formControl form .formGroup.hasValidation .inputMessages .description {
    font-size: 10px;
    font-style: italic;
    padding: 4px 0 16px; }
  .formControl form .formGroup.hasValidation .inputMessages .error {
    color: #c10000;
    font-style: italic; }

.formControl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  letter-spacing: normal;
  line-height: 1.25; }

.containerSmall {
  width: 480px; }

.containerLarge {
  width: 600px; }

.formControl {
  padding-bottom: 0; }
  .formControl form {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    margin: 23px 24px 0; }
    .formControl form.wideLabels label {
      min-width: 60px; }
    .formControl form .logicalGroup {
      border-top: 1px solid #d8d8d8;
      padding-top: 24px; }
      .formControl form .logicalGroup:first-of-type {
        border-top: none;
        padding-top: 0; }
    .formControl form.continued {
      margin-top: 0; }
      .formControl form.continued .logicalGroup:first-of-type {
        border-top: 1px solid #d8d8d8;
        padding-top: 24px; }
    .formControl form .formGroup {
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      margin-bottom: 24px; }
      .formControl form .formGroup .inputContainer {
        -ms-flex: 0 0 240px;
            flex: 0 0 240px; }
        .formControl form .formGroup .inputContainer.center {
          -ms-flex-pack: center;
              justify-content: center; }
        .formControl form .formGroup .inputContainer.favorField {
          -ms-flex: 1 0 240px;
              flex: 1 0 240px; }
        .formControl form .formGroup .inputContainer.large {
          -ms-flex: 0 0 450px;
              flex: 0 0 450px; }
        .formControl form .formGroup .inputContainer.medium {
          -ms-flex: 0 0 315px;
              flex: 0 0 315px; }
      .formControl form .formGroup label {
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
        .formControl form .formGroup label.favorField {
          overflow: hidden;
          -o-text-overflow: ellipsis;
             text-overflow: ellipsis;
          max-width: 178px;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
      .formControl form .formGroup .toggleLabelLarge {
        font-size: 16px !important;
        font-weight: normal;
        line-height: 1.5;
        padding-top: 0; }
      .formControl form .formGroup .disabledToggle {
        cursor: default; }
      .formControl form .formGroup .input {
        height: 30px;
        width: 100%; }
      .formControl form .formGroup .textarea {
        height: 102px;
        padding-top: 6px;
        padding-bottom: 6px;
        width: 100%; }
      .formControl form .formGroup .selectContainer {
        display: -ms-flexbox;
        display: flex; }
      .formControl form .formGroup input[type='checkbox'] {
        margin-top: auto;
        margin-bottom: auto; }
      .formControl form .formGroup .settingValue {
        -ms-flex-align: center;
            align-items: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        color: #4b4f54;
        display: -ms-flexbox;
        display: flex;
        font-size: 14px;
        font-weight: 200;
        height: 30px;
        -ms-flex-pack: start;
            justify-content: flex-start;
        line-height: 1.2;
        padding: 0.2em 0.5em 0; }
        .formControl form .formGroup .settingValue.ellipsize {
          overflow: hidden;
          -o-text-overflow: ellipsis;
             text-overflow: ellipsis;
          white-space: nowrap; }
      .formControl form .formGroup.vertical {
        -ms-flex-direction: column;
            flex-direction: column; }
        .formControl form .formGroup.vertical .inputContainer {
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
      .formControl form .formGroup.hasValidation {
        margin-bottom: 5px; }
      .formControl form .formGroup.error .input, .formControl form .formGroup.error .textarea {
        border-color: #c10000; }
      .formControl form .formGroup .iconStyle {
        font-size: 13px; }
      .formControl form .formGroup .tooltipButtonIcon {
        color: #4b45da;
        font-size: 17px;
        cursor: pointer; }
      .formControl form .formGroup .disabled {
        opacity: 0.5; }
      .formControl form .formGroup .searchInput input, .formControl form .formGroup .searchInput :global(.prompt), .formControl form .formGroup :global(.ui.search):not([role="combobox"]) input, .formControl form .formGroup :global(.ui.search):not([role="combobox"]) :global(.prompt) {
        border-radius: 4px !important;
        border: 1px solid #a5a7aa !important;
        padding: 0 0.5em !important; }
        .formControl form .formGroup .searchInput input:hover, .formControl form .formGroup .searchInput :global(.prompt):hover, .formControl form .formGroup :global(.ui.search):not([role="combobox"]) input:hover, .formControl form .formGroup :global(.ui.search):not([role="combobox"]) :global(.prompt):hover {
          background-color: white !important; }
        .formControl form .formGroup .searchInput input:focus, .formControl form .formGroup .searchInput :global(.prompt):focus, .formControl form .formGroup :global(.ui.search):not([role="combobox"]) input:focus, .formControl form .formGroup :global(.ui.search):not([role="combobox"]) :global(.prompt):focus {
          background-color: white !important;
          outline: none;
          border-color: #4b45da !important; }
        .formControl form .formGroup .searchInput input:disabled, .formControl form .formGroup .searchInput :global(.prompt):disabled, .formControl form .formGroup :global(.ui.search):not([role="combobox"]) input:disabled, .formControl form .formGroup :global(.ui.search):not([role="combobox"]) :global(.prompt):disabled {
          background-color: #dbdcdd !important; }
    .formControl form .generalError {
      color: #4b4f54;
      display: -ms-flexbox;
      display: flex;
      font-size: 13px;
      position: relative;
      text-align: left; }
      .formControl form .generalError.center {
        text-align: center; }
      .formControl form .generalError .error {
        color: #c10000;
        font-style: italic;
        left: 0;
        line-height: initial;
        margin-top: 5px;
        max-width: 100%;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        overflow: hidden;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        white-space: nowrap; }
