@charset "UTF-8";
/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
@-webkit-keyframes expandIn {
  from {
    max-height: 0; }
  to {
    max-height: 165px; } }
@keyframes expandIn {
  from {
    max-height: 0; }
  to {
    max-height: 165px; } }

.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  background: #4b4f54 !important;
  background-size: cover !important;
  height: 100%;
  position: relative;
  -webkit-transition: width .5s ease;
  -o-transition: width .5s ease;
  transition: width .5s ease;
  width: 60px; }

.wide {
  width: 213px; }

.navHandle {
  position: absolute;
  top: 0;
  right: -10px;
  width: 20px;
  height: 100%;
  cursor: col-resize;
  z-index: 3; }

.menuContainer {
  background: #4b4f54 !important;
  background-size: cover !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 16px 0 5px 8px !important; }
  .menuContainer .menuItem {
    font-size: 14px !important;
    height: auto !important;
    margin: 0 !important;
    padding: 6px 10px 6px 6px !important;
    position: relative; }
    .menuContainer .menuItem .navLink {
      opacity: 0.6;
      color: #fff;
      display: -ms-flexbox;
      display: flex;
      padding: 0;
      height: 36px;
      margin: 0;
      text-decoration: none; }
      .menuContainer .menuItem .navLink i {
        border-radius: 50%;
        font-size: 20px;
        color: #fff;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex: 0 0 36px;
            flex: 0 0 36px; }
      .menuContainer .menuItem .navLink span {
        -ms-flex-align: center;
            align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1;
            flex: 1 1;
        margin: 0;
        padding: 1px 5px 0 11px; }
      .menuContainer .menuItem .navLink:hover {
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 40px; }
      .menuContainer .menuItem .navLink.active {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 40px;
        font-weight: bold; }
        .menuContainer .menuItem .navLink.active:after {
          font-family: ls-desktop-and-web-iconography;
          content: "";
          color: #f2f2f2;
          position: absolute;
          right: -5px;
          top: 16px;
          font-size: 14px; }
  .menuContainer .subMenu {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    height: auto;
    max-height: 0;
    padding: 0 0 0 25px;
    overflow: hidden; }
    .menuContainer .subMenu .menuItem {
      margin-bottom: 0 !important; }
      .menuContainer .subMenu .menuItem .navLink {
        width: 155px;
        padding: 0 20px; }
        .menuContainer .subMenu .menuItem .navLink.active {
          opacity: 1;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 40px;
          font-weight: bold; }
          .menuContainer .subMenu .menuItem .navLink.active i {
            border: 2px solid #fff; }
          .menuContainer .subMenu .menuItem .navLink.active:after {
            display: none;
            content: none; }
    .menuContainer .subMenu.open {
      -webkit-animation: expandIn 0.3s;
              animation: expandIn 0.3s;
      max-height: 165px; }

.logoContainer {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  color: #ffffff;
  font-size: 18px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  bottom: 15px; }
  .logoContainer i {
    display: -ms-flexbox !important;
    display: flex !important;
    width: auto !important;
    font-size: 1.1em !important; }

.returnToOriginalAdminConsoleContainer {
  margin-top: 26px;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.returnToOriginalAdminConsoleButton {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 197px;
  overflow: hidden;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  padding: 4px 13px 5px;
  font-size: 13px !important;
  font-weight: 200;
  color: #5d6267; }
  .returnToOriginalAdminConsoleButton i {
    font-size: 14px;
    color: #4b45da; }
  .returnToOriginalAdminConsoleButton span {
    width: 166px;
    overflow: hidden;
    letter-spacing: -0.1px;
    margin-left: 6px;
    line-height: 1.33; }
