.container {
  background: #f2f2f2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%; }
  .container .mainContainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    -ms-overflow-y: auto; }
    .container .mainContainer .scrollContainer {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex: 1 1;
          flex: 1 1;
      overflow-y: auto; }
      .container .mainContainer .scrollContainer .contentContainer {
        background: #f2f2f2;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex: 1 1;
            flex: 1 1;
        -ms-flex-pack: justify;
            justify-content: space-between;
        height: 100%;
        min-width: 870px; }
