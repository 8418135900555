/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.tooltip {
  background-color: #4b4f54;
  border-radius: 4px;
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
          box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
  font-size: 13px;
  line-height: 14px;
  margin: 0;
  padding: 11px 10px 11px 8px;
  position: absolute;
  text-align: center;
  text-shadow: none;
  visibility: hidden;
  white-space: nowrap;
  z-index: 999; }

.tooltip:after {
  border: 6px solid transparent;
  content: '';
  height: 0;
  position: absolute;
  width: 0; }

/* default heights, width and margin w/o Javscript */
.tooltip.top {
  bottom: calc(100% + 6px);
  right: 50%;
  -webkit-transform: translate(50%, 0);
      -ms-transform: translate(50%, 0);
          transform: translate(50%, 0); }
  .tooltip.top.snug {
    bottom: 100%; }

.tooltip.top:after {
  border-top-color: #4b4f54;
  bottom: -12px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.tooltip.top.shift-left {
  right: 0;
  -webkit-transform: translate(0%, 0);
      -ms-transform: translate(0%, 0);
          transform: translate(0%, 0); }

.tooltip.top.shift-left:after {
  left: 90%;
  -webkit-transform: translate(-90%, 0);
      -ms-transform: translate(-90%, 0);
          transform: translate(-90%, 0); }

/* tool tip position right */
.tooltip.right {
  left: calc(100% + 6px);
  top: 50%;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%); }
  .tooltip.right.snug {
    left: 100%; }

.tooltip.right:after {
  border-right-color: #4b4f54;
  left: -12px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%); }

/* tool tip position left */
.tooltip.left {
  right: calc(100% + 6px);
  top: 50%;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%); }
  .tooltip.left.snug {
    right: 100%; }

.tooltip.left:after {
  border-left-color: #4b4f54;
  right: -12px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%); }

/* tool tip position bottom */
.tooltip.bottom {
  right: 50%;
  top: calc(100% + 6px);
  -webkit-transform: translate(50%, 0);
      -ms-transform: translate(50%, 0);
          transform: translate(50%, 0); }
  .tooltip.bottom.snug {
    top: 100%; }

.tooltip.bottom:after {
  border-bottom-color: #4b4f54;
  left: 50%;
  top: -12px;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.tooltip.bottom.shift-left {
  right: 0;
  -webkit-transform: translate(0%, 0);
      -ms-transform: translate(0%, 0);
          transform: translate(0%, 0); }

.tooltip.bottom.shift-left:after {
  left: 90%;
  -webkit-transform: translate(-90%, 0);
      -ms-transform: translate(-90%, 0);
          transform: translate(-90%, 0); }

.tooltip.bottom.shift-right {
  right: auto; }

.tooltip.bottom.shift-right:after {
  left: 15%; }

/* tooltip on focus left and right */
.on-focus .tooltip.left,
.on-focus .tooltip.right {
  margin-top: -19px; }

/* on hover of element containing tooltip default */
*:not(.on-focus):hover > .tooltip,
.on-focus input:focus + .tooltip {
  -webkit-transition: visibility 0.5s step-end;
  -o-transition: visibility 0.5s step-end;
  transition: visibility 0.5s step-end;
  visibility: visible; }
