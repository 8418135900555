/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }
  .container .headerPlaceholder {
    height: 110px;
    background: white; }
  .container .tabsContainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    -ms-flex-direction: column;
        flex-direction: column; }
    .container .tabsContainer .details {
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      list-style-type: none;
      margin: 0 8px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;
      -ms-flex: 1 1;
          flex: 1 1;
      background: white;
      position: relative; }
      .container .tabsContainer .details li {
        background-color: white;
        border-top: 1px solid #d8d8d8;
        padding: 12px 25px; }
        .container .tabsContainer .details li:first-child {
          border-top: none; }
        .container .tabsContainer .details li .title {
          font-size: 14px; }
          .container .tabsContainer .details li .title .time {
            color: #918f90;
            float: right;
            font-weight: 200;
            margin-left: 25px; }
        .container .tabsContainer .details li .detail {
          font-weight: 200; }
          .container .tabsContainer .details li .detail:last-child {
            margin-bottom: 0; }
        .container .tabsContainer .details li .noResults {
          margin-bottom: 0;
          text-align: center; }
          .container .tabsContainer .details li .noResults :global(.icon) {
            vertical-align: top; }

.infiniteContainer {
  -ms-flex-positive: 1;
      flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
