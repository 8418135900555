.container {
  width: 100%;
  margin: 0;
  padding: 0; }
  .container iframe {
    min-height: 621px;
    min-width: 974px;
    margin-top: 3px;
    overflow: hidden;
    border: none;
    outline: none; }
    .container iframe.hidden {
      min-height: 0;
      min-width: 0;
      height: 0;
      width: 0;
      border: none; }
  .container .homeScreenBody {
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    display: -ms-flexbox;
    display: flex;
    min-height: 624px;
    min-width: 974px; }

.overrideModalClass {
  padding: 0 !important;
  margin: 0 !important; }
