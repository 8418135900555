/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.toastContainer {
  top: 4em !important; }

.toast {
  display: -ms-flexbox;
  display: flex;
  width: 286px;
  border-radius: 4px !important;
  -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2) !important;
  background-color: #ffffff;
  padding: 0 !important;
  min-height: 56px !important; }

.container {
  display: -ms-flexbox;
  display: flex;
  width: 286px;
  height: 56px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff; }

.iconContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 56px;
      flex: 0 0 56px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 16px; }

.redIcon {
  color: white;
  background-color: #c10000; }

.messageContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex: 1 1;
      flex: 1 1;
  font-size: 10px;
  line-height: 1.6;
  font-weight: 200;
  color: #4b4f54;
  padding: 12px 0 12px 16px; }

.rightContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 49px;
      flex: 0 0 49px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
