/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  max-width: 434px; }

.formGroupHorizontal {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 14px;
  -ms-flex-align: center;
      align-items: center; }
  .formGroupHorizontal label {
    -ms-flex-pack: left;
        justify-content: left;
    margin-right: 8px; }

.shareWithInput {
  -ms-flex: 1 1;
      flex: 1 1;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 30px; }

.iconStyle {
  color: #4b45da;
  margin-left: 8px;
  font-size: 17px;
  cursor: pointer; }

.readOnlyMeetingViewSetting {
  -ms-flex: 1 1;
      flex: 1 1;
  opacity: 0.5 !important;
  color: #4b4f54 !important;
  background-color: #d8d8d8 !important;
  border-radius: 4px !important;
  height: 30px;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.viewerContainer {
  margin-top: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0 7px;
  font-size: 14px;
  width: 100%;
  min-height: 30px;
  opacity: 0.5;
  color: #4b4f54;
  background-color: #d8d8d8;
  border-radius: 4px;
  max-height: 97px;
  overflow-y: auto; }

.pill {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 4px;
  color: #231f20;
  border: 1px solid #4b45da;
  font-size: 13px;
  padding: 4px 8px;
  margin: 4px; }
