.container {
  padding: 10px; }
  .container div[class="ReactTable"] {
    background-color: #EFEFEF;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0 0 4px 4px; }
    .container div[class="ReactTable"] div[class="rt-table"] div[class*="rt-thead"] {
      font-family: NimbusSans-Bold, sans-serif !important;
      font-weight: bold;
      padding-left: 0;
      border-bottom: 1px solid #d8d8d8;
      margin-left: 10px;
      margin-right: 10px; }
      .container div[class="ReactTable"] div[class="rt-table"] div[class*="rt-thead"] div[class*="rt-tr"] {
        font-size: 11px; }
        .container div[class="ReactTable"] div[class="rt-table"] div[class*="rt-thead"] div[class*="rt-tr"] div[class*="rt-th"] {
          height: 40px;
          margin-left: 0; }
    .container div[class="ReactTable"] div[class="rt-table"] div[class*="rt-tbody"] div[class="rt-tr-group"] div[class*="rt-tr"] div[class*="rt-td"] {
      font-size: 11px;
      margin-left: 10px; }

.participantPopup {
  position: absolute;
  top: 42px;
  left: 16px;
  z-index: 1000;
  background: #efefef;
  border-radius: 4px;
  padding: 5px 10px;
  -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5); }
  .participantPopup thead {
    border-bottom: 1px solid #d8d8d8; }
  .participantPopup th {
    padding: 5px 10px; }
  .participantPopup td {
    padding: 5px 10px; }
  .participantPopup tbody {
    display: block;
    max-height: 100px;
    overflow: auto; }
  .participantPopup thead, .participantPopup tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* even columns width , fix width of table too*/ }
  .participantPopup thead {
    width: calc( 100% - 1em); }
  .participantPopup table {
    width: 400px; }
  .participantPopup::after {
    position: absolute;
    top: -20px;
    left: 36px;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 10px solid #efefef; }
