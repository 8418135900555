/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.upgradeNotificationContainer button[role*="button"] {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.upgradeNotificationContainer button[role*="button"] {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .upgradeNotificationContainer button:hover[role*="button"] {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .upgradeNotificationContainer button:disabled[role*="button"] {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.upgradeNotificationContainer {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  list-style-type: none;
  margin: 10px 8px;
  background: white;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -ms-flex-align: center;
      align-items: center; }
  .upgradeNotificationContainer .prompt {
    -ms-flex: 1 1;
        flex: 1 1;
    padding-right: 20px;
    font-weight: 200;
    font-size: 13px;
    line-height: 1.75; }
  .upgradeNotificationContainer button[role*="button"] {
    margin-right: 20px; }
