/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.spacer {
  height: 16px; }

.narrowColumn {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center; }

.presenting {
  color: #4b45da; }

.flexCol {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin: 16px 8px 24px;
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 25px 17px; }

.summaryRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.summaryColumn {
  color: #000000;
  font-size: 12px;
  margin-right: 30px;
  -ms-flex: 1 1;
      flex: 1 1; }
  .summaryColumn:last-child {
    margin-right: 0; }
  .summaryColumn dl {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    margin-bottom: 16px; }
    .summaryColumn dl:last-child {
      margin-bottom: 0; }
  .summaryColumn dt {
    min-width: 120px; }
  .summaryColumn dd {
    font-weight: 200;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    padding-right: 5px;
    margin-left: 0; }
