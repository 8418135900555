.structure {
  min-height: 680px;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  overflow: auto;
  margin-bottom: 12px; }

.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }
  .container .tabsContainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    -ms-flex-direction: column;
        flex-direction: column; }

.flexCol {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.headerList {
  list-style-type: none;
  margin: 0 0 8px;
  padding: 0; }
  .headerList > li {
    display: inline-block; }
  .headerList > li + li {
    display: inline-block;
    border-left: 1px solid #4b4f54;
    height: 20px;
    padding-left: 16px;
    margin-left: 16px;
    line-height: 20px; }

.recording {
  color: #c10000; }
