.container {
  display: -ms-flexbox;
  display: flex;
  min-height: 400px !important;
  width: 550px;
  max-width: 550px !important;
  color: #4b4f54; }
  .container .wrapper {
    padding: 0 25px; }
  .container .title {
    height: 40px;
    font-size: 22px;
    line-height: 1.25;
    text-align: center; }
  .container .body {
    margin-top: 23px;
    margin-bottom: 27px;
    padding: 0 15px;
    height: 48px;
    font-size: 14px;
    line-height: 16px;
    text-align: center; }
  .container .footnote {
    margin: 20px 0;
    font-size: 13px;
    padding: 0 50px; }
  .container .profileList {
    border: 1px solid #ccc;
    min-height: 50px;
    max-height: 150px;
    overflow-y: scroll;
    padding: 0;
    margin: 0 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }
    .container .profileList .emptyList {
      padding: 10px;
      font-size: 15px; }
    .container .profileList.loading {
      -ms-flex-pack: center;
          justify-content: center; }
    .container .profileList .loader {
      -ms-flex-item-align: center;
          align-self: center; }
    .container .profileList .profileRow {
      min-height: 50px;
      padding: 0 15px;
      border-bottom: 1px solid #ccc;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center; }
      .container .profileList .profileRow:last-child {
        border-bottom: none; }
      .container .profileList .profileRow span {
        margin-left: 20px;
        font-size: 16px; }
