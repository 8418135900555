/* style label */
/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  -ms-flex-pack: start;
      justify-content: flex-start;
  padding: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .container > span {
    font-size: 13px;
    line-height: 16px;
    margin: 2px 0 0 8px; }
  .container > input {
    position: absolute;
    visibility: hidden; }
    .container > input + i {
      background: gray;
      border-radius: 50%;
      border: 1px solid #4b45da;
      -webkit-box-shadow: inset 0 0 0 9px #fff;
              box-shadow: inset 0 0 0 9px #fff;
      display: inline-block;
      height: 18px;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
      vertical-align: middle;
      width: 18px; }
    .container > input:checked + i {
      -webkit-box-shadow: inset 0 0 0 3px #fff;
              box-shadow: inset 0 0 0 3px #fff;
      background: #4b45da; }
  .container:not(.disabled):hover > input:not(:checked) + i {
    background: lightgray;
    -webkit-box-shadow: inset 0 0 0 3px #fff;
            box-shadow: inset 0 0 0 3px #fff; }
  .container.disabled > span {
    color: #a5a7aa; }
  .container.disabled > input + i {
    border: 1px solid #a5a7aa; }
  .container.disabled > input:checked + i {
    background: #a5a7aa; }

/**
 Needed to short-circuit transition.
 Otherwise, radio button remains visible for the
 length of the transition when visibility is changed
 */
.hideNow {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }
