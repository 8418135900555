.dateRangePicker {
  border-radius: 0;
  padding: 5px; }
  .dateRangePicker .ranges {
    width: 160px;
    text-align: left; }
  .dateRangePicker ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .dateRangePicker li {
    font-size: 13px;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    padding: 3px 12px;
    margin-bottom: 8px;
    cursor: pointer; }
  .dateRangePicker li:active, .dateRangePicker li:hover {
    background: #3CBFAE !important;
    border: 1px solid #3CBFAE !important;
    color: #fff; }
