.container {
  background-color: #f2f2f2;
  color: #555;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  min-width: 900px;
  padding: 0; }
  .container .content {
    background-color: #f2f2f2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    margin-bottom: 15px;
    margin-right: 15px;
    min-width: 500px;
    padding: 0;
    width: 100%; }
    .container .content :global(.contentBody) {
      height: 100%; }
    .container .content div[class*="ReactTable"] {
      margin: 0 8px;
      -ms-flex-positive: 1;
          flex-grow: 1; }
      .container .content div[class*="ReactTable"] div[class*="rt-table"] div[class~="rt-tr"] {
        padding: 0 17px 0 0 !important; }
      .container .content div[class*="ReactTable"] div[class*="rt-table"] div[class~="rt-tbody"] {
        -ms-flex: 1 1 270px;
            flex: 1 1 270px;
        min-height: 250px;
        overflow-x: hidden; }
        .container .content div[class*="ReactTable"] div[class*="rt-table"] div[class~="rt-tbody"] div[class~="rt-tr"] {
          -ms-flex-positive: 0 !important;
              flex-grow: 0 !important;
          -webkit-transform: scale(1);
              -ms-transform: scale(1);
                  transform: scale(1); }
    .container .content div[class="rt-thead -header"] {
      border-bottom: 1px solid #d8d8d8;
      -webkit-box-shadow: none;
              box-shadow: none;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
      .container .content div[class="rt-thead -header"] div[class~="rt-tr"] {
        padding: 0 17px 0 0 !important; }
