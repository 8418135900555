.container {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 1;
      flex: 1 1;
  margin: 0 8px 8px 0;
  min-width: 500px;
  overflow: hidden;
  padding: 18px 15px 10px 15px;
  width: 100%; }
  .container.wide {
    -ms-flex: 2 1 auto;
        flex: 2 1 auto;
    min-height: 146px; }
  .container .chart {
    -ms-flex: 1 1;
        flex: 1 1;
    width: 100%;
    display: -ms-flexbox;
    display: flex; }
    .container .chart div {
      width: 100%; }
  .container .usageMessage {
    text-align: center;
    color: #dd5a5b;
    font-size: 18px; }
