/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container .inputField input {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .container .inputField input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .container .inputField input:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .container .inputField input::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .container .inputField input:-ms-input-placeholder {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .container .inputField input:focus {
    outline: none;
    border-color: #4b45da; }
  .container .inputField input:disabled {
    background-color: #dbdcdd; }

.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 20px; }
  .container label {
    color: #4b4f54;
    font-size: 13px;
    line-height: 1.36;
    min-width: 140px;
    margin: 0 8px 0 0; }
  .container .inputField {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    position: relative; }
    .container .inputField input {
      font-size: 14px;
      height: 30px;
      text-align: right;
      width: 50px; }
    .container .inputField .error {
      color: #ca0000;
      font-size: 11px;
      left: 0;
      position: absolute;
      top: 100%;
      padding-top: 3px;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
  .container.isBusy {
    cursor: wait; }
