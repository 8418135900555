.container {
  display: -ms-flexbox;
  display: flex;
  width: 480px;
  color: #4b4f54; }
  .container .title {
    height: 50px;
    font-size: 20px;
    line-height: 1.25;
    text-align: center;
    padding: 0 25px; }
  .container .body {
    margin-top: 23px;
    margin-bottom: 27px;
    padding: 0 80px;
    height: 48px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center; }
