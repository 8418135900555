/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.tableContainer {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px; }
  .tableContainer div[class*="rt-td"]:not(.tableCell) {
    min-width: 30px !important; }

.tableHeaderTitle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #000000; }
  .tableHeaderTitle:first-of-type {
    margin-left: 1vw; }

.tableCell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .tableCell:first-of-type {
    margin-left: 1vw; }

.emptyCell {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  background-color: #f2f2f2;
  border-radius: 4px;
  height: 30px;
  width: 100%; }

:global .ui.tab.active, :global .ui.tab.open {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-positive: 1;
      flex-grow: 1; }
