.show {
  visibility: visible;
  height: 100%; }

.hide {
  visibility: hidden;
  height: 0;
  z-index: -1; }

.isAdd {
  padding-top: 13px !important; }

.buttonTabsContainer:global(.ui.secondary.pointing.menu) :global(.item):first-of-type {
  margin-left: 22px !important; }
