.container {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
  margin: 0 8px 8px;
  min-width: 726px;
  padding: 0 6px; }
  .container.loading {
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column;
    height: 150px;
    -ms-flex-pack: center;
        justify-content: center; }
    .container.loading > i {
      font-size: 27px; }
