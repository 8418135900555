/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.dropdown {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .dropdown::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .dropdown:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .dropdown::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .dropdown:-ms-input-placeholder {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .dropdown:focus {
    outline: none;
    border-color: #4b45da; }
  .dropdown:disabled {
    background-color: #dbdcdd; }

.container {
  margin: 20px;
  padding: 20px 20px 40px 20px;
  background: white;
  overflow: auto; }

.header {
  font-weight: 500;
  font-size: 16px;
  color: #4b4f54; }

.toggleLabel {
  font-size: 14px !important;
  color: #4b4f54;
  font-weight: 500; }

.dropdown {
  min-width: 140px !important; }

.roleRow {
  display: -ms-flexbox;
  display: flex;
  height: 55px; }
  .roleRow:first-child {
    height: 30px; }
  .roleRow > div {
    min-width: 180px;
    min-height: 30px;
    height: 30px;
    font-size: 14px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
    .roleRow > div:first-child {
      min-width: 200px;
      -ms-flex-pack: start;
          justify-content: flex-start;
      -ms-flex-align: center;
          align-items: center; }

.roleTable {
  margin-top: 20px; }
