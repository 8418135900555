/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container .btnSendInvite {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.container .btnSendInvite {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .container .btnSendInvite:hover {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .container .btnSendInvite:disabled {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.container .inviteLinkContainer .buttonContainer .button {
  background: none;
  border: none;
  color: #4b45da;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 17px !important;
  padding: 2px;
  position: relative;
  z-index: 100; }
  .container .inviteLinkContainer .buttonContainer .button:hover {
    color: #290088; }
  .container .inviteLinkContainer .buttonContainer .button:disabled {
    opacity: 0.5; }

.container {
  -ms-flex-align: stretch;
      align-items: stretch;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  font-size: 16px;
  -ms-flex-pack: center;
      justify-content: center;
  width: 480px; }
  .container .subTitle {
    margin-top: 32px;
    margin-bottom: 13px;
    padding: 0 32px; }
  .container .btnSendInvite {
    -ms-flex-item-align: center;
        align-self: center;
    font-size: 17px !important;
    height: 40px;
    min-width: 150px; }
  .container .line {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    margin-top: 24px; }
  .container .cta {
    margin: 45px 95px 11px; }
  .container .inviteLinkContainer {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    margin: 0 32px 32px; }
    .container .inviteLinkContainer .inviteLink {
      -ms-flex-align: center;
          align-items: center;
      background-color: #d8d8d8;
      border-radius: 4px;
      color: #00bbb9;
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
      height: 44px;
      padding: 0 16px; }
      .container .inviteLinkContainer .inviteLink span {
        overflow: hidden;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        white-space: nowrap; }
    .container .inviteLinkContainer .buttonContainer {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
      .container .inviteLinkContainer .buttonContainer > * {
        margin: 0 0 0 14px; }
      .container .inviteLinkContainer .buttonContainer .button {
        font-size: 20px !important; }
      .container .inviteLinkContainer .buttonContainer .buttonCopy {
        font-size: 20px !important;
        min-width: 24px; }
