/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container article .formSection .buttonContainer button[role*="button"], .container article .integration button[role*="button"] {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 0.05em 22px 0; }

.container article .formSection .buttonContainer button[role*="button"], .container article .integration button[role*="button"] {
  background-color: #4b45da;
  border: 1px solid transparent;
  color: white; }
  .container article .formSection .buttonContainer button:hover[role*="button"], .container article .integration button:hover[role*="button"] {
    background-color: #290088;
    border-color: transparent;
    color: white; }
  .container article .formSection .buttonContainer button:disabled[role*="button"], .container article .integration button:disabled[role*="button"] {
    border-color: transparent;
    color: white;
    opacity: 0.5 !important; }

.container article .formSection .inputGroup .buttonIcon {
  background: none;
  border: none;
  color: #4b45da;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 17px !important;
  padding: 2px;
  position: relative;
  z-index: 100; }
  .container article .formSection .inputGroup .buttonIcon:hover {
    color: #290088; }
  .container article .formSection .inputGroup .buttonIcon:disabled {
    opacity: 0.5; }

.container article .formSection .inputGroup .input, .container article .formSection .inputGroup .textarea, .container article .formSection.horizontal .inputEmail, .container article .formSection.horizontal .inputLanguage {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .container article .formSection .inputGroup .input::-webkit-input-placeholder, .container article .formSection .inputGroup .textarea::-webkit-input-placeholder, .container article .formSection.horizontal .inputEmail::-webkit-input-placeholder, .container article .formSection.horizontal .inputLanguage::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .container article .formSection .inputGroup .input:-moz-placeholder, .container article .formSection .inputGroup .textarea:-moz-placeholder, .container article .formSection.horizontal .inputEmail:-moz-placeholder, .container article .formSection.horizontal .inputLanguage:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .container article .formSection .inputGroup .input::-moz-placeholder, .container article .formSection .inputGroup .textarea::-moz-placeholder, .container article .formSection.horizontal .inputEmail::-moz-placeholder, .container article .formSection.horizontal .inputLanguage::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .container article .formSection .inputGroup .input:-ms-input-placeholder, .container article .formSection .inputGroup .textarea:-ms-input-placeholder, .container article .formSection.horizontal .inputEmail:-ms-input-placeholder, .container article .formSection.horizontal .inputLanguage:-ms-input-placeholder {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .container article .formSection .inputGroup .input:focus, .container article .formSection .inputGroup .textarea:focus, .container article .formSection.horizontal .inputEmail:focus, .container article .formSection.horizontal .inputLanguage:focus {
    outline: none;
    border-color: #4b45da; }
  .container article .formSection .inputGroup .input:disabled, .container article .formSection .inputGroup .textarea:disabled, .container article .formSection.horizontal .inputEmail:disabled, .container article .formSection.horizontal .inputLanguage:disabled {
    background-color: #dbdcdd; }

.subheader {
  color: #4b4f54;
  font-weight: 200;
  margin: 3px 23px 9px; }

.disabled {
  cursor: default; }

.container {
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 8px 8px; }
  .container article {
    font-weight: 200;
    padding: 16px 25px; }
    .container article + article {
      border-color: #d8d8d8;
      border-style: solid;
      border-width: 1px 0 0; }
    .container article .headerRow {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between;
      -ms-flex-align: baseline;
          align-items: baseline; }
    .container article .title {
      color: #4b4f54;
      font-weight: normal; }
      .container article .title + .flexRow, .container article .title + .linkList, .container article .title + .flexSubscriptionRow {
        margin-top: 12px; }
    .container article .warning {
      margin-bottom: 13px; }
    .container article .error {
      color: #c10000;
      margin-top: 12px; }
    .container article .legend {
      font-size: 10px;
      color: rgba(0, 0, 0, 0.5); }
    .container article .flexColumn {
      -ms-flex-direction: column;
          flex-direction: column; }
    .container article .subscriptionButtons {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: start;
          align-items: flex-start;
      min-width: 150px; }
      .container article .subscriptionButtons button + button {
        margin-top: 12px; }
    .container article .flexSubscriptionRow {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -ms-flex-pack: justify;
          justify-content: space-between; }
      .container article .flexSubscriptionRow dl {
        color: #000000;
        margin: 0;
        -ms-flex-positive: 1;
            flex-grow: 1; }
        .container article .flexSubscriptionRow dl dt {
          display: inline-block;
          font-weight: normal;
          margin-right: 5px; }
          .container article .flexSubscriptionRow dl dt.bold {
            font-weight: bold; }
        .container article .flexSubscriptionRow dl dd {
          display: inline-block;
          font-weight: 200;
          margin: 0; }
    .container article .flexRow, .container article .flexColumn {
      margin-top: 10px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -ms-flex-pack: justify;
          justify-content: space-between; }
      .container article .flexRow dl.expireLabel, .container article .flexColumn dl.expireLabel {
        margin-top: 10px;
        text-align: left; }
        .container article .flexRow dl.expireLabel:last-of-type, .container article .flexColumn dl.expireLabel:last-of-type {
          text-align: left; }
        .container article .flexRow dl.expireLabel dt, .container article .flexColumn dl.expireLabel dt {
          font-weight: bold; }
      .container article .flexRow dl, .container article .flexColumn dl {
        color: #000000;
        margin: 0;
        -ms-flex-positive: 1;
            flex-grow: 1;
        width: 33%; }
        .container article .flexRow dl dt, .container article .flexColumn dl dt {
          display: inline-block;
          font-weight: normal;
          margin-right: 5px; }
          .container article .flexRow dl dt.bold, .container article .flexColumn dl dt.bold {
            font-weight: bold; }
        .container article .flexRow dl dd, .container article .flexColumn dl dd {
          display: inline-block;
          font-weight: 200;
          margin: 0; }
      .container article .flexRow dl:last-of-type, .container article .flexColumn dl:last-of-type {
        text-align: right; }
      .container article .flexRow dl:first-of-type, .container article .flexColumn dl:first-of-type {
        text-align: left; }
    .container article .subscriptionCanceledStatement {
      margin-top: 10px;
      color: black;
      font-weight: 200;
      line-height: 16px; }
    .container article .description {
      color: black;
      font-weight: 200;
      line-height: 16px;
      margin-bottom: 0.75rem; }
      .container article .description.em {
        font-style: italic;
        font-weight: normal; }
      .container article .description:last-of-type {
        margin-bottom: 0; }
      .container article .description a {
        font-weight: normal; }
      .container article .description + .formSection, .container article .description + form > .formSection:first-child {
        margin-top: 21px; }
    .container article .linkList {
      line-height: 2.5;
      list-style: none;
      margin: 0;
      padding: 0; }
    .container article .formSection {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      margin-top: 13px; }
      .container article .formSection .phoneFieldArray div label {
        padding-top: 8px;
        text-align: right;
        width: 140px; }
      .container article .formSection .inputGroup {
        -ms-flex-align: start;
            align-items: flex-start;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-pack: start;
            justify-content: flex-start;
        margin-top: 16px;
        /* note: the label is not vertically aligned to the center of the adjacent input so that if the label wraps, the
         * first line of the label is still aligned with the input correctly */ }
        .container article .formSection .inputGroup label {
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          color: black;
          font-size: 13px;
          margin-bottom: 0;
          padding: 6px 8px 0 0;
          width: 90px; }
        .container article .formSection .inputGroup .input {
          font-size: 14px;
          height: 30px;
          width: 277px; }
        .container article .formSection .inputGroup .textarea {
          min-height: 108px;
          padding-bottom: 4px;
          padding-top: 4px;
          width: 277px; }
        .container article .formSection .inputGroup hr {
          border-top: 1px dashed #a5a7aa;
          height: 0;
          margin-top: 6px;
          width: 277px; }
        .container article .formSection .inputGroup .buttonIcon {
          z-index: auto; }
          .container article .formSection .inputGroup .buttonIcon.remove {
            color: #4b4f54;
            font-size: 15px !important;
            height: 30px;
            margin-left: 16px; }
            .container article .formSection .inputGroup .buttonIcon.remove :hover {
              color: black; }
          .container article .formSection .inputGroup .buttonIcon span {
            color: #4b4f54;
            font-size: 15px;
            font-style: italic;
            margin-left: 12px; }
        .container article .formSection .inputGroup.compact {
          margin-top: 8px; }
        .container article .formSection .inputGroup.inset {
          margin-left: 9px; }
        .container article .formSection .inputGroup.wide label {
          width: 157px; }
        .container article .formSection .inputGroup.wide .input {
          width: 320px; }
        .container article .formSection .inputGroup.wide .textarea {
          width: 320px; }
        .container article .formSection .inputGroup:first-child {
          margin-top: 0; }
      .container article .formSection .optionGroup {
        margin-top: 8px; }
        .container article .formSection .optionGroup label {
          color: black;
          font-size: 13px;
          line-height: 1.8; }
      .container article .formSection .layoutImages {
        display: block;
        padding-left: 10px; }
        .container article .formSection .layoutImages img {
          height: auto;
          margin-left: 8px;
          width: 90px; }
      .container article .formSection .buttonContainer {
        -ms-flex-align: center;
            align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
            justify-content: flex-end;
        margin-bottom: 9px; }
        .container article .formSection .buttonContainer button[role*="button"] {
          height: 30px;
          min-width: 120px; }
        .container article .formSection .buttonContainer .confirmation {
          color: #00afaa;
          font-style: italic;
          margin-right: 16px; }
      .container article .formSection .previewHeader {
        color: #4b4f54;
        display: -ms-flexbox;
        display: flex;
        font-style: italic;
        margin: 10px 0 8px; }
        .container article .formSection .previewHeader > * {
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
        .container article .formSection .previewHeader .alert {
          color: #00afaa;
          text-align: right; }
      .container article .formSection .previewArea {
        background-color: #d8d8d8;
        border-radius: 4px;
        color: #4b4f54;
        font-family: ls-text-font, sans-serif;
        line-height: 1.5;
        margin-bottom: 11px;
        padding: 35px 40px; }
      .container article .formSection .tooltipButtonIcon {
        color: #4b45da;
        font-size: 17px;
        cursor: pointer; }
      .container article .formSection.horizontal {
        -ms-flex-direction: row;
            flex-direction: row;
        margin-bottom: 9px; }
        .container article .formSection.horizontal .inputContainer {
          -ms-flex: 1 1 auto;
              flex: 1 1 auto; }
        .container article .formSection.horizontal .input {
          font-size: 14px;
          max-width: 650px;
          min-height: 30px;
          min-width: 400px; }
        .container article .formSection.horizontal .inputEmail {
          font-size: 14px;
          height: 30px;
          width: 400px; }
        .container article .formSection.horizontal .inputLanguage {
          font-size: 14px;
          height: 30px;
          width: 200px; }
        .container article .formSection.horizontal .buttonContainer {
          margin-bottom: 0; }
        .container article .formSection.horizontal.rowsOf2 {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
          .container article .formSection.horizontal.rowsOf2 > * {
            display: -ms-flexbox;
            display: flex;
            width: 50%; }
          .container article .formSection.horizontal.rowsOf2 > *:nth-child(n+3) {
            margin-top: 13px; }
          .container article .formSection.horizontal.rowsOf2 > *:nth-child(odd) {
            padding-right: 25px !important; }
      .container article .formSection + .description {
        margin-top: 13px; }
    .container article > .formSection:first-child {
      margin-top: 0; }
    .container article .integration {
      -ms-flex-align: start;
          align-items: flex-start;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-pack: start;
          justify-content: flex-start; }
      .container article .integration img {
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
        height: auto;
        width: 24px; }
      .container article .integration .description {
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
        margin: 0;
        padding: 0 24px 0 16px; }
      .container article .integration button[role*="button"] {
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
        font-size: 14px;
        height: 30px;
        min-width: 120px;
        padding: 0 10px; }
    .container article.columns {
      display: -ms-flexbox;
      display: flex; }
      .container article.columns > *:first-child {
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
        margin-right: 13px; }
      .container article.columns > form > .formSection:first-child, .container article.columns > .formSection:first-child {
        margin-top: 0; }
      .container article.columns .formSection {
        -ms-flex-negative: 0;
            flex-shrink: 0; }
        .container article.columns .formSection .input {
          font-size: 14px;
          height: 30px;
          min-width: 200px; }
        .container article.columns .formSection .buttonContainer {
          margin-top: 24px; }
  .container .dropdownOptionText {
    display: -ms-flexbox;
    display: flex; }
    .container .dropdownOptionText .country {
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      white-space: nowrap; }
    .container .dropdownOptionText .phoneNumber {
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
  .container .buttonLink {
    font-size: 13px;
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #00c18b;
    cursor: pointer; }
  .container .statusBar {
    display: -ms-flexbox;
    display: flex; }
    .container .statusBar .yellow {
      color: #ff9e16; }
    .container .statusBar .green {
      color: #00c18b; }
    .container .statusBar span, .container .statusBar i {
      margin-right: 4px; }
  .container .portalError {
    color: #c10000;
    font-size: 12px;
    margin-top: 5px;
    max-width: 150px; }
