/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container .style input {
  background-color: white;
  border-radius: 4px !important;
  border: 1px solid #a5a7aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4b4f54;
  font-weight: 200;
  min-height: 0;
  outline: none;
  padding: 0 0.5em; }
  .container .style input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a5a7aa;
    opacity: 1; }
  .container .style input:-moz-placeholder {
    /* Firefox 18- */
    color: #a5a7aa;
    opacity: 1; }
  .container .style input::-moz-placeholder {
    /* Firefox 19+ */
    color: #a5a7aa;
    opacity: 1; }
  .container .style input:-ms-input-placeholder {
    /* IE 10+ */
    color: #a5a7aa;
    opacity: 1; }
  .container .style input:focus {
    outline: none;
    border-color: #4b45da; }
  .container .style input:disabled {
    background-color: #dbdcdd; }

.container {
  position: relative; }
  .container .style {
    /* Elastic search drop-down results */ }
    .container .style input {
      height: 30px;
      font-size: 16px;
      font-weight: 200;
      width: 100%;
      -ms-flex-item-align: stretch;
          align-self: stretch;
      -ms-flex-align: center;
          align-items: center; }
    .container .style div[class="ui icon input"] {
      display: block; }
    .container .style div[class="results transition visible"] {
      width: 100% !important;
      overflow: hidden auto;
      -ms-overflow-y: auto;
      max-height: 239px; }
      .container .style div[class="results transition visible"] :global(.result) {
        padding: 8px;
        text-align: left; }
      .container .style div[class="results transition visible"] div[class="header"] {
        font-family: inherit !important;
        font-weight: 200 !important; }
      .container .style div[class="results transition visible"] div[class="title"] {
        font-family: inherit !important;
        font-weight: 200 !important; }
  .container .resetButton {
    background: none;
    border: none;
    color: #4b45da;
    cursor: pointer;
    height: auto;
    padding: 0;
    position: absolute;
    right: 5px;
    top: 8px; }

.loaderContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%; }
