.container {
  display: -ms-flexbox;
  display: flex;
  min-height: 300px !important;
  width: 550px;
  max-width: 550px !important;
  color: #4b4f54; }
  .container .wrapper {
    padding: 0 25px !important; }
  .container .title {
    font-size: 22px;
    padding: 0 80px;
    line-height: 1.25;
    text-align: center; }
  .container .body {
    margin-top: 23px;
    margin-bottom: 27px;
    padding: 0 15px;
    line-height: 16px;
    text-align: left; }
