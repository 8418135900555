.container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #4b4f54; }
  .container > div {
    width: 100%; }
  .container div[role='combobox'] {
    min-height: 30px !important; }
  .container i[class='dropdown icon'] {
    margin-top: -5px;
    top: 5px; }
  .container div[role='option'] {
    min-height: 30px; }
  .container input[class="search"] {
    padding: .35em 1.0em !important;
    padding-left: 8px !important;
    font-size: 14px;
    line-height: .9em !important;
    left: 0;
    height: 30px; }
  .container div[role="alert"] {
    top: 0 !important;
    left: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    max-width: 205px !important; }
  .container div[class='default text'] {
    top: 0 !important;
    left: 0 !important; }
  .container .iconContainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    margin-left: 5px; }
