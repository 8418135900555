@charset "UTF-8";
/**
    Global Overrides
 */
/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
/* React-table sorting highlight colors */
div[class*="-sort-asc"],
div[class*="-sort-desc"] {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

div[class*="-sort-asc"]:after,
div[class*="-sort-desc"]:after {
  font-family: ls-desktop-and-web-iconography;
  content: "";
  font-size: 10px;
  margin-left: 15px;
  margin-top: 2px; }

div[class*="rt-th"]:not(:first-of-type) {
  height: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

div[class*="rt-thead"] div[class*="rt-tr"]:first-child {
  border-radius: 4px 4px 0 0; }

div[class*="rt-th"] {
  border-right: none !important; }

div[class="rt-td"] {
  border: none !important;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex; }

div[class="rt-tr-group"] {
  border: none !important;
  -ms-flex: 0 0 auto !important;
      flex: 0 0 auto !important; }

div[class*="ReactTable"] {
  border-radius: 4px;
  background: #fff; }

div[class="rt-noData"] {
  color: #4b4f54 !important;
  height: 150px;
  width: 300px;
  opacity: 1;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border: none;
  top: 30% !important;
  font-size: 20px;
  font-family: NimbusSans-Light, sans-serif;
  text-align: center; }

div[class="rt-noData"]:after {
  font-family: ls-desktop-and-web-iconography;
  content: "";
  color: #4b4f54;
  position: absolute;
  top: 0%;
  font-size: 45px; }

.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer; }

div[class*="-sort-desc"]:after {
  content: ""; }

i[class*="icon"]:hover,
i[class*="icon"]:active {
  background-color: transparent; }

i[class*="icon-info"] {
  text-shadow: 1px 1px 1px #e3e3e3; }

i[class*="icon-info"]:hover {
  cursor: pointer;
  text-shadow: 1px 1px 1px #b3b3b3; }

i[class*="icon-info"]:active {
  outline: none;
  text-shadow: 1.5px 1.5px 1.5px #b3b3b3; }

/**
  Table header
 */
div[class*="rt-thead -header"] div[class*="rt-tr"] {
  font-size: 14px; }

/**
  Table rows
 */
div[class*="rt-tr-group"] div[class*="rt-tr"] {
  font-size: 13px;
  height: inherit; }

div[class*="rt-thead -header"] div[class*="rt-th"] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

div[class*="DateRangePickerInput"] button:first-of-type svg {
  fill: #4b45da; }

div[class*="DateRangePicker_picker"] {
  z-index: 200; }
