.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-pack: center;
      justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .container .content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    height: 150px;
    max-width: 360px;
    opacity: 1;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-item-align: center;
        align-self: center;
    text-align: center;
    font-size: 20px;
    font-family: NimbusSans-Light, sans-serif; }
    .container .content .iconContainer {
      color: #4b4f54;
      font-size: 45px; }
