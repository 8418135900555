/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  -ms-flex-align: center;
      align-items: center;
  background-color: #290088;
  color: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 1 40px;
      flex: 0 1 40px;
  min-height: 60px;
  padding: 0 15px;
  -ms-flex-pack: end;
      justify-content: flex-end; }
  .container .walkmeLink {
    color: #d8d8d8;
    display: -ms-flexbox;
    display: flex;
    font-size: 23px !important;
    padding-right: 15px; }
  .container .rightSide {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    color: #d8d8d8; }
