.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 15px;
  -webkit-box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75);
          box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.75); }
  .container .headerLegend {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    font-family: NimbusSans-Bold, sans-serif;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc; }
    .container .headerLegend > span {
      -ms-flex: 1 1;
          flex: 1 1;
      margin-right: 10px; }
      .container .headerLegend > span:first-of-type {
        max-width: 100px; }
