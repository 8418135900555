/*
 * This is a bundler for all useful Sass tools such as functions, constants, & mixins
 */
/*
 * Color Definitions:
 * - try to limit color definitions
 * - add new colors here as necessary
 */
/*
 * Color Names:
 * - these should be meaningful color names for our shared components
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 */
/*
 * Font Weights
 * - keyed from font-face declarations in _styleFonts.scss
 */
/*
 * use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * - usage: at the end of the style declaration include the mixin (example: @include ellipsize;)
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin
 */
/* Color Definitions:
 * - try to limit color definitions
 * - add new local-only colors here as necessary (add new shared color definitions in ux.assets)
 */
/* Color Names:
 * - these should be meaningful color names
 * - try to use 'mix()' instead of 'rgba()' where applicable to guarantee color
 * - you may reference Color Definitions from the ux.assets or any defined above
 */
/* Font Settings
 * - each project should determine family and base font size since they vary
 * - use a font-family name keyed from the style-linked font-face declarations in Lifesize.ux.assets
 */
.container {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  padding: 9px; }
  .container .messageOnly {
    color: #4b4f54;
    margin: 40px;
    text-align: center; }
    .container .messageOnly :global(.icon) {
      font-size: 27px; }
    .container .messageOnly h4 {
      margin: 9px 0 0; }
  .container .videoFrame {
    position: relative;
    height: 0;
    -ms-flex: 1 1;
        flex: 1 1;
    margin: -8px;
    min-width: 200px;
    max-width: 100%; }
    .container .videoFrame iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      margin: 0;
      padding: 0; }
  .container .name {
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
    padding: 0;
    margin: 26px 0 0;
    font-size: 24px;
    font-family: NimbusSans-Light, sans-serif; }
  .container .descriptionContainer {
    margin-top: 8px;
    font-size: 13px;
    font-family: NimbusSans-Light, sans-serif; }
  .container .timeAndViewsContainer {
    margin-top: 8px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-size: 13px;
    font-family: NimbusSans-Light, sans-serif; }
  .container .videoDetails {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    .container .videoDetails .ownerAndVmr {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
      .container .videoDetails .ownerAndVmr .vmrLinkContainer {
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        max-width: 300px; }
  .container .buttonBar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end;
    -ms-flex-align: center;
        align-items: center; }
  .container .button span {
    white-space: nowrap; }
  .container .button button {
    color: #4b45da;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit; }
    .container .button button i {
      font-size: 1.3em;
      margin: 0 6px; }

.verticalDivider {
  margin: 0 8px;
  width: 1px;
  height: 14px;
  background-color: #d8d8d8; }

.horizontalDivider {
  margin: 14px 0;
  width: 100%;
  height: 1px;
  background-color: #d9d9d9; }

.viewsContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.eye {
  margin-top: -2px;
  margin-right: 4px;
  width: 13px;
  height: 13px;
  border-radius: 75% 15%;
  position: relative;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #767676; }

.eye:before {
  content: '';
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  left: 4px;
  top: 4px; }
