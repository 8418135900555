@charset "UTF-8";
.container {
  /* - Chrome ≤56,
   - Safari 5-10.0
   - iOS Safari 4.2-10.2
   - Opera 15-43
   - Opera Mobile 12-12.1
   - Android Browser 2.1-4.4.4
   - Samsung Internet ≤6.2
   - QQ Browser */
  /* Firefox 4-18 */
  /* Firefox 19-50 */
  /*
    - Internet Explorer 10–11
    - Internet Explorer Mobile 10-11
    Note: Please leave the color declarations as they are
  */
  /*
    Edge (also supports ::-webkit-input-placeholder)
    Note: Please leave the color declarations as they are
  */ }
  .container :first-child {
    width: 100%; }
  .container :first-child::before {
    top: 8px;
    left: 10px;
    position: absolute;
    font-size: 14px;
    color: #231f20;
    opacity: 1; }
  .container .info {
    width: 100%;
    border-bottom: none; }
    .container .info input {
      border-bottom: none; }
    .container .info input:focus {
      border-bottom: none; }
  .container input::-webkit-input-placeholder {
    color: #a1a2a6 !important;
    font-size: 14px;
    opacity: 1; }
  .container input::-moz-placeholder {
    color: #a1a2a6 !important;
    font-size: 14px;
    opacity: 1; }
  .container input::-ms-input-placeholder {
    color: #a1a2a6 !important;
    font-size: 14px;
    opacity: 1; }
  .container input::placeholder {
    color: #a1a2a6 !important;
    font-size: 14px;
    opacity: 1; }
  .container input::-webkit-input-placeholder {
    color: #a1a2a6 !important;
    font-size: 14px;
    opacity: 1; }
  .container :-moz-placeholder {
    color: #a1a2a6 !important;
    font-size: 14px;
    opacity: 1; }
  .container ::-moz-placeholder {
    color: #a1a2a6 !important;
    font-size: 14px;
    opacity: 1; }
  .container :-ms-input-placeholder {
    color: #a1a2a6 !important;
    color: gray !important;
    padding-top: 5px !important;
    font-size: 14px;
    opacity: 0.9; }
  .container ::-ms-input-placeholder {
    color: #a1a2a6 !important;
    color: gray !important;
    padding-top: 5px !important;
    font-size: 14px;
    opacity: 1; }
  .container input {
    border: none !important; }
